import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Body,
  FormHeader,
  Header,
  Modal,
  QrScannerButton,
  View,
} from 'components';
import { compose } from 'redux';
import { clearState } from 'store/actions';
import {
  fetchDistricts,
  fetchStates,
} from 'store/lazyLoadedDictionary/actions';
import { fetchProfile } from 'store/profile/actions';
import { getAddresses } from 'store/profile/selectors';
import { fetchRatePlans } from 'store/rateManager/actions';
import {
  fetchBreakdown,
  fetchReservation,
  fetchReservationExtended,
  fetchReservationGenerateKeys,
  fetchReservationPurchases,
} from 'store/reservation/actions';
import {
  getReservation,
  getTooMuchReservations,
} from 'store/reservation/selectors';
import { fetchRoomDetails, fetchRoomType } from 'store/room/actions';
import { getRoomType } from 'store/room/selectors';
import { getErrors } from 'store/selectors';
import { getIsDistrictEnabled } from 'store/setup/selectors';
import { refreshUi, setIsIPad } from 'store/ui/actions';
import { Address } from 'types/Api/Profile';
import { RoomType } from 'types/Api/Room';
import { ApiError } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';

import AuthorizationForm from './AuthorizationForm';

interface FormValues {
  lastName: string;
  identificationNumber: string;
  roomNumber?: string;
}

interface PassedProps {}

interface CheckInAuthProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation {
  fetchReservationGenerateKeys: typeof fetchReservationGenerateKeys;
  fetchBreakdown: typeof fetchBreakdown;
  fetchRoomType: typeof fetchRoomType;
  setIsIPad: typeof setIsIPad;
  fetchProfile: typeof fetchProfile;
  fetchReservation: typeof fetchReservation;
  fetchReservationPurchases: typeof fetchReservationPurchases;
  fetchRoomDetails: typeof fetchRoomDetails;
  clearState: typeof clearState;
  fetchRatePlans: typeof fetchRatePlans;
  fetchReservationExtended: typeof fetchReservationExtended;
  fetchStates: typeof fetchStates;
  fetchDistricts: typeof fetchDistricts;
  reservation: {
    id: string;
    profileId: string;
    roomId: string;
    roomTypeId: string;
    statusCode: {
      code: string;
    };
  };
  reservationVersion: number;
  tooMuchReservations: boolean;
  addresses: Address[];
  roomType: RoomType;
  refreshUi: typeof refreshUi;
  errors: ApiError[];
  isDistrictEnabled: boolean;
}

interface CheckInAuthState {
  authorizationError: string;
  isLoading: boolean;
  isSecondFactorVisible: boolean;
}

class CheckInAuth extends PureComponent<CheckInAuthProps, CheckInAuthState> {
  public static defaultProps = {
    reservation: {},
    reservationVersion: '',
  };

  public state = {
    authorizationError: '',
    isLoading: false,
    isSecondFactorVisible: false,
  };

  public componentDidMount() {
    this.clearState();
    this.assignCallbacks();
    this.checkVersion();
  }

  public render() {
    const { t } = this.props;
    const { authorizationError, isLoading, isSecondFactorVisible } = this.state;

    return (
      <View modal={{ isLoading }} hideCounter>
        <Modal
          isOpen={!!authorizationError}
          type="error"
          defaultError={t(this.defaultError)}
          onClick={this.onModalClick}
        />
        <Header title={t('GENERATE_KEYS')} />
        <Body>
          <FormHeader
            title={t('USE_QR_CODE_SENT')}
            subtitle={t('FOLLOW_THE_INSTRUCTION')}
          />
          <QrScannerButton onSubmit={this.onSubmit} />
          <AuthorizationForm
            isSecondFactorVisible={isSecondFactorVisible}
            onSubmit={this.onSubmit}
          />
        </Body>
      </View>
    );
  }

  public onModalClick = () => {
    this.clearState();
    this.setState({
      authorizationError: '',
      isSecondFactorVisible: false,
    });
  };

  /* eslint-disable camelcase */
  private assignCallbacks = () => {
    const global = window as any;
    // eslint-disable-next-line max-len
    global.kp_VersionAPI_requestProductName_callback =
      this.kp_VersionAPI_requestProductName_callback;
  };

  private kp_VersionAPI_requestProductName_callback = (productName: any) => {
    const { setIsIPad } = this.props;
    setIsIPad();
  };

  private checkVersion = () => {
    const global = window as any;
    global.kp_VersionAPI_requestProductName(
      'kp_VersionAPI_requestProductName_callback'
    );
  };
  /* eslint-enable camelcase */

  private clearState = () => {
    const { clearState } = this.props;
    clearState();
  };

  private onSubmit = async (values: FormValues) => {
    try {
      const { lastName, identificationNumber, roomNumber } = values;
      const { history, refreshUi, fetchReservationGenerateKeys } = this.props;

      this.enableLoading();
      refreshUi();

      await fetchReservationGenerateKeys(
        lastName.trim(),
        identificationNumber.trim(),
        roomNumber?.trim()
      );

      if (this.checkIfReceivedReservation()) {
        await this.loadNeededData();

        const { authorizationError } = this.state;
        if (!authorizationError) history.push(Router.nextStepURL);
      }
    } catch (error) {
      this.disableLoading();
      this.setState({ authorizationError: error.message });
    }
  };

  private checkIfReceivedReservation = () => {
    const { tooMuchReservations } = this.props;
    if (tooMuchReservations) {
      this.setState({ isSecondFactorVisible: true });
      this.disableLoading();

      return false;
    }

    const { errors } = this.props;
    if (errors.length > 0) {
      const { message } = errors[0];
      throw new Error(message);
    }

    return true;
  };

  private loadNeededData = async () => {
    const {
      fetchBreakdown,
      fetchRoomType,
      fetchProfile,
      fetchReservation,
      fetchRoomDetails,
      fetchRatePlans,
      fetchReservationPurchases,
      fetchReservationExtended,
      fetchStates,
      fetchDistricts,
      reservation: { id, profileId, roomTypeId, roomId },
      i18n,
      isDistrictEnabled,
    } = this.props;

    await fetchProfile(profileId);

    const { addresses } = this.props;

    await Promise.all([
      ...addresses
        .map((address) => {
          if (!address.countryCode) return;
          const dictionaries = [
            fetchStates(address.countryCode, i18n.language),
          ];

          if (isDistrictEnabled) {
            dictionaries.push(
              fetchDistricts(address.countryCode, i18n.language)
            );
          }

          return dictionaries;
        })
        .flat(),
      fetchBreakdown(id),
      fetchReservation(id),
      fetchReservationPurchases(id),
      fetchRoomType(roomTypeId),
      fetchRoomDetails(roomId),
      fetchRatePlans(),
      fetchReservationExtended(id),
    ]);

    this.checkReservationStatus();
  };

  private checkReservationStatus = () => {
    const {
      reservation: {
        statusCode: { code },
      },
    } = this.props;
    const generateKeysAvailable =
      code === Configurator.reservationStatuses.IN_HOUSE ||
      code === Configurator.reservationStatuses.DUE_OUT;
    if (!generateKeysAvailable) {
      throw new Error(`Reservation has type ${code}`);
    }
  };

  private get defaultError() {
    const { authorizationError } = this.state;
    const isTooManyReservations =
      authorizationError ===
      Configurator.generateKeysErrorCodes.TOO_MANY_RESERVATIONS;

    return isTooManyReservations
      ? 'GENERATE_KEYS_TOO_MANY_RESERVATIONS'
      : 'GENERATE_KEYS_UNAVAILABLE';
  }

  private enableLoading = () => {
    this.setState({ isLoading: true });
  };

  private disableLoading = () => {
    this.setState({ isLoading: false });
  };
}

const mapStateToProps = (state: Store) => ({
  tooMuchReservations: getTooMuchReservations(state),
  reservation: getReservation(state),
  roomType: getRoomType(state),
  errors: getErrors(state),
  addresses: getAddresses(state),
  isDistrictEnabled: getIsDistrictEnabled(state),
});

const mapDispatchToProps = {
  fetchReservationGenerateKeys,
  fetchBreakdown,
  fetchReservation,
  fetchProfile,
  fetchRoomType,
  fetchRoomDetails,
  setIsIPad,
  fetchReservationPurchases,
  clearState,
  refreshUi,
  fetchRatePlans,
  fetchReservationExtended,
  fetchStates,
  fetchDistricts,
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInAuth) as (props: PassedProps) => JSX.Element;
