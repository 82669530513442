import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Big from 'big.js';
import { PurchaseItem } from 'types/Api/Availability';
import { AllPurchaseItems } from 'types/Api/Reservation';

import { Icon, IconTypes } from '@ac/kiosk-components';

import { Button, Grid, Link, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import AddonModal from '../AddonModal';
import { getBackground } from '../CheckInAddons.style';
import PreAddedButton from '../PreAddedButton';
import PreAddedModal from '../PreAddedModal';

import styles from './AddonsMenuElement.style';

interface AddonsMenuElementProps
  extends WithTranslation,
    WithStyles<typeof styles> {
  data: PurchaseItem;
  backgroundUrl?: string;
  input?: any;
  quantity?: number;
  onItemAdd: (itemName: string, itemNumber?: number) => void;
  onItemRemove: (itemName: string, itemNumber?: number) => void;
  onItemDelete: (itemName: string) => void;
  isPreAddedElement: boolean;
  allPreAddedPurchases: AllPurchaseItems;
  isElementFromRate: boolean;
}

interface AddonsMenuElementState {
  isEditModalOpen: boolean;
  isPreAddedModalOpen: boolean;
}

class AddonsMenuElement extends PureComponent<
  AddonsMenuElementProps,
  AddonsMenuElementState
> {
  public static defaultProps = {
    backgroundUrl: '',
    isPreAddedElement: false,
    isElementFromRate: false,
    allPreAddedPurchases: {
      purchasesElementsFromRate: [],
      preAddedPurchaseElements: [],
    },
  };

  public state = {
    isEditModalOpen: false,
    isPreAddedModalOpen: false,
  };

  public toggleModal = () => {
    const { isEditModalOpen } = this.state;

    return this.setState({ isEditModalOpen: !isEditModalOpen });
  };

  public render() {
    const {
      classes,
      t,
      data,
      data: { id, description, calculationRule, displayName, isPerPerson },
      backgroundUrl,
      tReady,
      input,
      onItemAdd,
      onItemRemove,
      onItemDelete,
      quantity,
      isPreAddedElement,
      allPreAddedPurchases,
      isElementFromRate,
      ...props
    } = this.props;
    const { isEditModalOpen, isPreAddedModalOpen } = this.state;
    const price = this.getAddonPrice();
    const buttonClasses = {
      label: classes.buttonLabel,
      root: classes.buttonRoot,
    };
    const title = displayName || description;
    const modalData = {
      id,
      price,
      calculationRule,
      title,
      isPerPerson,
      description,
      quantity: quantity || 0,
    };
    const isItemAdded = !!quantity;
    const subtitleClass = isItemAdded ? classes.subtitle : classes.onlySubtitle;
    const titleClass = isItemAdded ? classes.longTitle : classes.title;
    const wrapper = isPreAddedElement
      ? classes.preAddedWrapper
      : classes.wrapper;
    const wrapperClass =
      isItemAdded && !isPreAddedElement ? classes.wrapperActive : wrapper;

    return (
      <>
        <AddonModal
          input={input}
          isOpen={isEditModalOpen}
          onClose={this.handleModalConfirmClick}
          data={modalData}
          onItemAdd={onItemAdd}
          onItemRemove={onItemRemove}
          onItemDelete={onItemDelete}
          backgroundUrl={backgroundUrl}
          onBackdropClick={this.toggleModal}
          isPreAddedElement={isPreAddedElement}
          openPreAddeModal={this.openPreAddedModal}
          allPreAddedPurchases={allPreAddedPurchases}
          addedElements={quantity || 0}
          isElementFromRate={isElementFromRate}
        />
        <PreAddedModal
          isOpen={isPreAddedModalOpen}
          onModalClose={this.closePreAddedModal}
          isElementFromRate={isElementFromRate}
        />
        <Button
          className={wrapperClass}
          classes={buttonClasses}
          onClick={this.handleClick}
          {...props}
        >
          {isItemAdded && !isPreAddedElement && (
            <Link
              onClick={this.handleClick}
              className={classes.iconButton}
              color="primary"
            >
              <Icon type={IconTypes.editPen} className={classes.editPenIcon} />
            </Link>
          )}
          <Grid container className={classes.contentWrapper}>
            {isPreAddedElement ? (
              <PreAddedButton
                isPerPerson={isPerPerson}
                onClick={this.openPreAddedModal}
                className={classes.preAddedButton}
                allPreAddedPurchases={allPreAddedPurchases}
                purchaseItemId={id}
                isElementFromRate={isElementFromRate}
              />
            ) : (
              <Grid
                item
                className={classes.layout}
                style={{ ...getBackground(backgroundUrl) }}
              />
            )}
          </Grid>
          <Grid item className={classes.titleBar}>
            <Grid className={classes.leftRow}>
              <Typography className={titleClass}>{title}</Typography>
              {isItemAdded && !isPreAddedElement && (
                <Typography className={classes.subtitleBold}>
                  {this.getSubtitle()}
                </Typography>
              )}
            </Grid>
            {!isElementFromRate && (
              <Grid>
                <Typography className={classes.priceTitle}>
                  {this.getPrice()}
                </Typography>
                <Typography className={subtitleClass}>
                  {calculationRule.description}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Button>
      </>
    );
  }

  private handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.toggleModal();
  };

  private handleModalConfirmClick = (itemNumber?: number) => {
    const {
      onItemAdd,
      onItemRemove,
      input: { name },
      quantity,
      data: { isPerPerson },
    } = this.props;
    if (!quantity && isPerPerson) onItemAdd(name);
    if (itemNumber && itemNumber > 0) onItemAdd(name, itemNumber);
    if (itemNumber && !isPerPerson && itemNumber < 0)
      onItemRemove(name, itemNumber);
    this.toggleModal();
  };

  public getPurchaseElementQuantity = () => {
    const {
      allPreAddedPurchases,
      data: { id: purchaseItemId },
      isElementFromRate,
    } = this.props;

    const properPurchaseElements = isElementFromRate
      ? allPreAddedPurchases.purchasesElementsFromRate
      : allPreAddedPurchases.preAddedPurchaseElements;

    const purchaseElement =
      properPurchaseElements &&
      properPurchaseElements.length &&
      properPurchaseElements.find(
        (item) => item.purchaseElementId === purchaseItemId
      );

    return purchaseElement && purchaseElement.quantity;
  };

  private getAddonPrice = () => {
    const {
      data: { price },
    } = this.props;

    return price.gross;
  };

  public getPrice = () => {
    const {
      data: { isPerPerson },
    } = this.props;
    const purchaseElementQuantity = this.getPurchaseElementQuantity();
    const price = this.getAddonPrice();

    return isPerPerson && purchaseElementQuantity! > 1
      ? new Big(price).times(purchaseElementQuantity!).toFixed(2)
      : price.toFixed(2);
  };

  private getSubtitle = () => {
    const {
      t,
      data: { isPerPerson },
      quantity,
      isPreAddedElement,
    } = this.props;
    const isItemAdded = Boolean(quantity);
    if (isItemAdded && isPreAddedElement && !quantity) return;

    return quantity! > 1 || !isPerPerson
      ? t('ITEMS_ADDED', { count: quantity })
      : t('ADDED');
  };

  private openPreAddedModal = (e: React.MouseEvent) => {
    e.stopPropagation();

    return this.setState({ isPreAddedModalOpen: true });
  };

  private closePreAddedModal = () =>
    this.setState({ isPreAddedModalOpen: false });
}

export default withStyles(styles)(withTranslation()(AddonsMenuElement));
