import { api } from 'config';
import { CALL_API } from 'store/middleware/api';

import types from './types';

export const fetchAvailablePurchaseItems = (
  startDate: string,
  endDate: string
) => ({
  [CALL_API]: {
    endpoint: api.AVAILABILITY.PURCHASE_ELEMENTS,
    params: {
      stayFromInclusive: startDate,
      stayToExclusive: endDate,
    },
    paginate: true,
    types: [
      types.FETCH_AVAILABLE_PURCHASE_ITEMS_REQUEST,
      types.FETCH_AVAILABLE_PURCHASE_ITEMS_SUCCESS,
      types.FETCH_AVAILABLE_PURCHASE_ITEMS_FAILURE,
    ],
  },
});
