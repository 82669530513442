import { Action } from '@ac/library-utils/dist/declarations';

import { BaseKioskConfigurationEntity } from '@gss/api/KioskApi/entries';

import { updateDictionaryStatusData } from './utils/updateDictionaryStatusData';
import LazyLoadedDictionaryStore, {
  DictionaryStatuses,
  StateChangeAction,
} from './interfaces';
import types from './types';

const initialState: LazyLoadedDictionaryStore = {
  states: {},
  districts: {},
};

export default (state = initialState, action: Action<StateChangeAction>) => {
  switch (action.type) {
    case types.FETCH_STATES_REQUEST: {
      return updateDictionaryStatusData(
        state,
        action.payload,
        'states',
        DictionaryStatuses.fetching
      );
    }

    case types.FETCH_STATES_SUCCESS: {
      return updateDictionaryStatusData(
        state,
        action.payload,
        'states',
        DictionaryStatuses.completed
      );
    }

    case types.FETCH_STATES_FAILURE: {
      return updateDictionaryStatusData(
        state,
        action.payload,
        'states',
        DictionaryStatuses.failed
      );
    }

    case types.FETCH_DISTRICTS_REQUEST: {
      return updateDictionaryStatusData(
        state,
        action.payload,
        'districts',
        DictionaryStatuses.fetching
      );
    }

    case types.FETCH_DISTRICTS_SUCCESS: {
      return updateDictionaryStatusData(
        state,
        action.payload,
        'districts',
        DictionaryStatuses.completed
      );
    }

    case types.FETCH_DISTRICTS_FAILURE: {
      return updateDictionaryStatusData(
        state,
        action.payload,
        'districts',
        DictionaryStatuses.failed
      );
    }

    default:
      return state;
  }
};
