import { ReducersMapObject } from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as checkInAddon } from 'views/CheckInAddons';
import { reducer as checkInCommunicationDetails } from 'views/CheckInCommunicationDetails';
import { reducer as checkInConfirmation } from 'views/CheckInConfirmation';
import { reducer as checkInId } from 'views/CheckInID';
import { reducer as checkInIDScanning } from 'views/CheckInIDScanning';
import { reducer as checkInRoom } from 'views/CheckInRoom';
import { reducer as checkOutCharges } from 'views/CheckOutCharges';
import { reducer as checkOutMinibar } from 'views/CheckOutMinibar';
import { reducer as checkOutPayment } from 'views/CheckOutPayment';

import Store from '@LEGACY/types/Store';

import aggregator from './aggregator/reducer';
import availability from './availability/reducer';
import cashiering from './cashiering/reducer';
import checkInProcess from './checkInProcess/reducer';
import checkOutProcess from './checkOutProcess/reducer';
import entities from './entities/reducer';
import files from './files/reducer';
import housekeeping from './housekeeping/reducer';
import lazyLoadedDictionary from './lazyLoadedDictionary/reducer';
import paymentInterface from './paymentInterface/reducer';
import profile from './profile/reducer';
import propertyManagement from './propertyManagement/reducer';
import rateManager from './rateManager/reducer';
// Shared reducers
import reservation from './reservation/reducer';
import room from './room/reducer';
import setup from './setup/reducer';
import ui from './ui/reducer';
// action types
import { CLEAR_STATE } from './types';

export const legacyReducers = {
  form,
  reservation,
  profile,
  room,
  files,
  lazyLoadedDictionary,
  housekeeping,
  setup,
  checkInAddon,
  checkInRoom,
  checkInId,
  entities,
  aggregator,
  propertyManagement,
  cashiering,
  checkOutMinibar,
  checkOutPayment,
  availability,
  rateManager,
  paymentInterface,
  checkOutCharges,
  checkInProcess,
  checkOutProcess,
  checkInIDScanning,
  checkInCommunicationDetails,
  checkInConfirmation,
  ui: ui as any,
} as unknown as ReducersMapObject<Store>;

export const legacyRootReducerEnchanter =
  (appReducer: any) => (state: any, action: any) => {
    const legacyReducerNames = Object.keys(legacyReducers).filter(
      (key) => key !== 'ui' && key !== 'setup'
    );

    const storeState =
      action.type === CLEAR_STATE
        ? Object.fromEntries(
            Object.entries(state).map(([storeKey, storeValue]) => {
              if (legacyReducerNames.includes(storeKey)) {
                return [storeKey, undefined];
              }

              return [storeKey, storeValue];
            })
          )
        : state;

    return appReducer(storeState, action);
  };
