import i18next from 'i18next';
import { getDetailedFolios } from 'store/cashiering/selectors';
import {
  getAllDistrictResults,
  getAllStateResults,
} from 'store/lazyLoadedDictionary/selectors';
import { getCurrentBusinessDate } from 'store/propertyManagement/selectors';
import {
  getProfileId,
  getReservation,
  isRatePlanConfidential,
} from 'store/reservation/selectors';
import { getName, getReservationDetails } from 'store/selectors';
import { getIsDistrictEnabled } from 'store/setup/selectors';
import Store from 'types/Store';
import { Configurator, DateManager, stringifyAddressData } from 'utils';
import { getCurrentFolioNumber } from 'views/CheckOutCharges/store/selectors';

import { convertImageTo, ImageMimeTypes } from '@ac/kiosk-components';

import { checkOutFolioTemplate } from '@gss/pdfTemplates';
import { blobToBase64, objectUrlToBlob } from '@gss/utils/files';

export default async function prepareCheckOutFolioPdfContent(
  state: Store,
  signature: Blob
) {
  const reservation = getReservation(state);
  const profileId = getProfileId(state);
  const reservationDetails = getReservationDetails(state);
  const guestName = getName(state);
  const states = getAllStateResults(state);
  const districts = getAllDistrictResults(state);
  const currentFolioNumber = getCurrentFolioNumber(state);
  const folios = getDetailedFolios(state);
  const businessDate = getCurrentBusinessDate(state);
  const isPriceConfidential = isRatePlanConfidential(state);
  const isDistrictEnabled = getIsDistrictEnabled(state);

  const currentFolio = folios[currentFolioNumber];
  const folioCode = currentFolio.folioTypeCode.code;
  const transactions = currentFolio?.transactions || [];
  const total = currentFolio?.grossBalance.amount?.toFixed(2);

  const date = DateManager.getFormattedDate(businessDate);
  const time = DateManager.getHour(new Date());

  const isCompanyFolio = folioCode === Configurator.folioTypeCodes.COMPANY;
  const isTravelAgentFolio =
    folioCode === Configurator.folioTypeCodes.TRAVEL_AGENT;
  const isIndividualFolioForDifferentProfile =
    folioCode === Configurator.folioTypeCodes.INDIVIDUAL &&
    profileId !== currentFolio.profileId;

  const { WELCOME_LOGO } = Configurator.settingsImagesCodes;
  const logoUrl = Configurator.settingImages[WELCOME_LOGO]?.content;
  const logoBlob = await objectUrlToBlob(logoUrl);

  const convertedLogo: Blob | undefined =
    logoBlob && logoBlob.type === ImageMimeTypes.PNG
      ? await convertImageTo(logoBlob, ImageMimeTypes.JPEG)
      : logoBlob;

  const logoBase64 = convertedLogo && (await blobToBase64(convertedLogo));
  const signatureBase64 = await blobToBase64(signature);

  const address = currentFolio.address
    ? stringifyAddressData(
        currentFolio.address,
        states,
        districts,
        i18next.language,
        isDistrictEnabled
      )
    : undefined;

  return checkOutFolioTemplate({
    period: reservationDetails?.bookingPeriod,
    logo: logoBase64,
    reservationDetails: {
      guestName: guestName as string,
      ...(isCompanyFolio && { company: currentFolio.profileName }),
      ...(isTravelAgentFolio && { travelAgent: currentFolio.profileName }),
      ...(isIndividualFolioForDifferentProfile && {
        individual: currentFolio.profileName,
      }),
      address,
      roomNumber: reservationDetails?.roomNumber,
      confirmationNumber: reservation?.confirmationNumber,
    },
    signature: signatureBase64,
    dateTime: `${date} ${time}`,
    total: isPriceConfidential ? Configurator.pricePlaceholder : total,
    tableData: transactions.map(
      ({ date, description, quantity, gross, totalGross, transactionType }) => {
        const isTransactionPayment =
          transactionType === Configurator.transactionCodes.PAYMENT;

        return {
          cells: [
            date,
            description,
            isTransactionPayment ? '' : gross,
            quantity ? String(quantity) : '',
            isTransactionPayment ? `-${totalGross}` : totalGross,
          ],
        };
      }
    ),
  });
}
