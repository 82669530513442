import i18next from 'i18next';
import { createSelector } from 'reselect';
import {
  getAllDistrictResults,
  getAllStateResults,
} from 'store/lazyLoadedDictionary/selectors';
import { getIsDistrictEnabled } from 'store/setup/selectors';
import { MembershipLevel, MembershipType } from 'types/Api/Entities';
import { ParsedKioskCommunicationType } from 'types/Api/ParsedKioskConfiguration';
import {
  Address,
  CommunicationChannel,
  CommunicationChannelToSend,
  LinkedProfileIds,
  Membership,
} from 'types/Api/Profile';
import Store from 'types/Store/ProfileStore';
import { Configurator, findAdditionalName, stringifyAddressData } from 'utils';

import { CommunicationMode } from '@ac/library-api';

const findAddress = (addresses: Address[] | undefined, code: string) =>
  addresses?.find((address) => address.typeCode === code);

const findPrimaryAddress = (addresses: Address[] | undefined) =>
  addresses?.find((address) => address.primary);

const findCommunicationChannel = (
  channels: CommunicationChannel[],
  code: string
) =>
  channels.find(
    (communicationChannel) => communicationChannel.typeCode === code
  );
const findPrimaryCommunicationChannel = (
  channels: CommunicationChannel[],
  code: string
) =>
  channels.find(
    (communicationChannel) =>
      communicationChannel.primary && communicationChannel.mode === code
  );

interface EntityType {
  id: string;
  code?: string;
}

function getEntityByType<Entity, T extends EntityType>({
  entities,
  entityTypes,
  entityCode,
  findEntity,
}: {
  entities: Entity[];
  entityTypes: T[];
  findEntity: (entities: Entity[], code: string) => Entity | undefined;
  entityCode: string;
}) {
  const entityType = Configurator.getEntityTypeCode(entityTypes, entityCode);

  return entityType ? findEntity(entities, entityType) : null;
}

export const getProfile = (state: Store) => state.profile.profiles[0];

export const getDataFetching = (state: Store) => state.profile.dataFetching;

export const getProfileSecondSurname = createSelector(getProfile, (profile) =>
  findAdditionalName(profile?.details?.additionalNames)
);

export const isFetching = createSelector(getDataFetching, (dataFetching) =>
  Object.values(dataFetching).includes(true)
);

export const getProfileConsents = createSelector(
  getProfile,
  (profile) => profile?.consents || []
);

export const getGuestLinkedProfileIds = createSelector(
  getProfile,
  (profile) => profile?.links
);

export const getGuestLinkedProfileIdsByRole = (
  profile: { links: LinkedProfileIds[] },
  code: string
) => {
  const linkedIds = profile?.links || [];

  return linkedIds
    .filter((elem) => elem.profileRole === code)
    .map((elem) => elem.profileId);
};

export const getProfilesViews = (state: Store) => state.profile.profilesViews;

export const getAddress = (addresses: any) =>
  findPrimaryAddress(addresses) ||
  findAddress(addresses, Configurator.defaultGuestAddressType?.code);

export const getCompanies = (state: Store) => state.profile.linkedCompanies;

export const getAllCompanies = (state: Store) => state.profile.companies;

export const isCompanyCreated = (state: Store) =>
  state.profile.isCompanyCreated;

export const getCompanyListData = createSelector(
  getCompanies,
  getAllStateResults,
  getAllDistrictResults,
  getIsDistrictEnabled,
  (companies, states, districts, isDistrictEnabled) =>
    companies.map((company) => {
      const {
        details: { fullName: companyName, taxId },
        addresses,
        id,
      } = company;

      const address = getAddress(addresses);
      const stringifiedAddress = address
        ? stringifyAddressData(
            address,
            states,
            districts,
            i18next.language,
            isDistrictEnabled
          )
        : undefined;

      return {
        companyName,
        taxId,
        id,
        address: stringifiedAddress,
      };
    })
);

export const getAllCompaniesDetails = createSelector(
  getAllCompanies,
  (companies) =>
    companies.map((company) => {
      const {
        details: { fullName: companyName, taxId },
        id,
      } = company;

      return {
        companyName,
        taxId,
        id,
      };
    })
);

export const getNewCompanyId = (state: Store) => state.profile.newCompanyId;

export const getNewIndividualId = (state: Store) =>
  state.profile.newIndividualId;

export const getIsIndividualCreated = (state: Store) =>
  state.profile.isIndividualCreated;

export const getCompany = (state: Store) => state.profile.linkedCompanies[0];

export const getCompanyAddresses = createSelector(getCompany, (company) =>
  company ? company.addresses : []
);

export const getCompanyDetails = createSelector(
  getCompany,
  (company) => company?.details
);

export const getProfileDetails = createSelector(
  getProfile,
  (profile) => profile?.details
);

export const getAddresses = createSelector(
  getProfile,
  (profile) => profile?.addresses
);

export const getCommunicationChannels = createSelector(
  getProfile,
  (profile) => profile?.communicationDetails || []
);

export const getCompanyCommunicationChannels = (state: Store) => {
  return state.profile.companyCommunicationChannels;
};

export const getTravelAgentCommunicationChannels = (state: Store) => {
  return state.profile.travelAgentCommunicationChannels;
};

export const getIndividualCommunicationChannels = (state: Store) => {
  return state.profile.individualCommunicationChannels;
};

export const getProfileFromFolio = (state: Store) => {
  return state.profile.profileFromFolio;
};

export const getActiveDocuments = createSelector(
  getProfile,
  (profile) =>
    profile?.identificationDocuments.filter((document) => {
      return Configurator.documentTypes.some(
        ({ code }) => code === document.typeCode
      );
    }) || []
);

export const getParentVersion = createSelector(
  getProfile,
  (profile) => profile?.version
);

export const getMembership = createSelector(
  getProfile,
  (profile) => profile?.memberships[0]
);

export const getAllProfiles = (state: Store) => state.profile.profiles;

export const getProfileErrors = (state: Store) => state.profile.errors;

export const getMembershipLevel = (membership: Membership) =>
  membership
    ? Configurator.membershipLevels.find(
        (level) => level.code === membership.levelCode
      )
    : null;

export const getMembershipType = (membershipLevel: MembershipLevel) =>
  membershipLevel
    ? Configurator.membershipTypes.find(
        (type) => type.id === membershipLevel.membershipTypeId
      )
    : null;

export const getMembershipDescription = (
  membershipType: MembershipType,
  membershipLevel: MembershipLevel
) => {
  const typeDescription = Configurator.getDescription(
    membershipType.description
  );
  const levelDescription = Configurator.getDescription(
    membershipLevel.description
  );

  return `${typeDescription} ${levelDescription}`;
};

export const getFullMembership = createSelector(getMembership, (membership) => {
  const membershipLevel = getMembershipLevel(membership);
  if (!membershipLevel) return '';

  const membershipType = getMembershipType(membershipLevel);
  if (!membershipType) return '';

  return getMembershipDescription(membershipType, membershipLevel);
});

export const getCommunicationChannel =
  (type: string, mode: string) => (channels: CommunicationChannel[]) => {
    const primaryChannel = channels.find(
      (channel) => channel.typeCode === type
    );

    return primaryChannel || channels.find((channel) => channel.mode === mode);
  };

export const getEmail = createSelector(getCommunicationChannels, (channels) => {
  const primaryEmail = getEntityByType<
    CommunicationChannel,
    ParsedKioskCommunicationType
  >({
    entities: channels,
    entityTypes: Configurator.emailTypes,
    entityCode: Configurator.entityCodes.EMAIL_TYPE,
    findEntity: findCommunicationChannel,
  });
  if (primaryEmail) return primaryEmail;

  return findPrimaryCommunicationChannel(channels, CommunicationMode.Email);
});

export const getEmailsToSend = createSelector(
  getCommunicationChannels,
  (channels) => {
    const { getActiveEntityCodeOrDefault, emailTypes } = Configurator;

    return channels
      .filter((channel) => channel.mode === CommunicationMode.Email)
      .filter((channel) =>
        getActiveEntityCodeOrDefault(emailTypes, channel.typeCode, '')
      );
  }
);

export const getMobile = createSelector(
  getCommunicationChannels,
  (channels) => {
    const primaryMobile = getEntityByType<
      CommunicationChannel,
      ParsedKioskCommunicationType
    >({
      entities: channels,
      entityTypes: Configurator.mobileTypes,
      entityCode: Configurator.entityCodes.MOBILE_TYPE,
      findEntity: findCommunicationChannel,
    });

    if (primaryMobile) return primaryMobile;

    return (
      findPrimaryCommunicationChannel(channels, CommunicationMode.Mobile) ||
      findCommunicationChannel(
        channels,
        Configurator.defaultCommunicationMobileType?.code
      )
    );
  }
);

export const getMobilesToSend = createSelector(
  getCommunicationChannels,
  (channels) => {
    const { getActiveEntityCodeOrDefault, mobileTypes } = Configurator;

    return channels
      .filter((channel) => channel.mode === CommunicationMode.Mobile)
      .filter((channel) =>
        getActiveEntityCodeOrDefault(mobileTypes, channel.typeCode, '')
      );
  }
);

export const getProfileAddress = createSelector(getAddresses, (addresses) =>
  getAddress(addresses)
);

export const getCompanyAddress = createSelector(
  getCompanyAddresses,
  (addresses) => getAddress(addresses)
);

export const getTravelAgents = (state: Store) => state.profile.travelAgents;

export const getEmailAddressIfExist = (
  communicationChannels: CommunicationChannel[],
  billingEmailType?: string
) => {
  const emailFromDefaultTypeCode = communicationChannels?.find(
    (channel: CommunicationChannelToSend | CommunicationChannel) => {
      if (billingEmailType) {
        return channel.typeCode === billingEmailType;
      }

      return false;
    }
  );
  const primaryEmail = communicationChannels?.find(
    (channel: CommunicationChannelToSend | CommunicationChannel) => {
      return 'mode' in channel
        ? channel.mode === CommunicationMode.Email && channel.primary
        : channel.modeCode === CommunicationMode.Email || channel.isPrimary;
    }
  );
  if (emailFromDefaultTypeCode) return emailFromDefaultTypeCode.details;
  if (primaryEmail) return primaryEmail.details;
};

export const getWasEditEmailFormInitialized = (state: Store) =>
  state.profile.wasEditEmailFormInitialized;

export const getAllCompanyIndividualProfiles = createSelector(
  getAllProfiles,
  getAllCompanies,
  getCompanies,
  (individual, allCompanies, linkedCompanies) => {
    const uniqueCompanies = allCompanies.filter(
      (company) => !linkedCompanies.find((item) => item.id === company.id)
    );

    return [...individual, ...uniqueCompanies, ...linkedCompanies];
  }
);

export const getIsProfileDuplicateDetected = (state: Store) =>
  state.profile.profileDuplicatesDetected;

export const getIfGuestAcceptedProfileDuplicateRisk = (state: Store) =>
  state.profile.guestAcceptedProfileDuplicateRisk;
