import { api } from 'config';
import { change, getFormValues } from 'redux-form';
import { CALL_API } from 'store/middleware/api';
import { getPreAddedPurchaseElements } from 'store/reservation/selectors';
import { Dispatch, GetState } from 'store/utils/actions';
import { PurchaseElementData } from 'types/Api/Reservation';

import { FormValues } from '../types';

import { getConfirmedNewPurchaseOrders } from './selectors';
import types from './types';

export const fetchConfirmedNewPurchaseOrders =
  (reservationId: string, orderdItemsIds: string[]) =>
  (dispatch: Dispatch, getState: GetState) => {
    const previousPreAddedElements = getPreAddedPurchaseElements(getState());

    return dispatch({
      [CALL_API]: {
        endpoint: api.RESERVATIONS.PURCHASE_ELEMENTS(reservationId),
        payload: {
          previousPreAddedElements,
          orderdItemsIds,
        },
        types: [
          types.FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_REQUEST,
          types.FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_SUCCESS,
          types.FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_FAILURE,
        ],
      },
    });
  };

export const updateFormValuesByTransactionId =
  (formId: string, reservationId: string) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const orderdItems: FormValues = getFormValues(formId)(getState());
    if (!orderdItems)
      return dispatch({ type: types.CLEAR_CONFIRMED_NEW_PURCHASE_ORDERS });

    const orderdItemsIds = Object.keys(orderdItems);
    await dispatch(
      fetchConfirmedNewPurchaseOrders(reservationId, orderdItemsIds)
    );
    const newPurcaseOrders = getConfirmedNewPurchaseOrders(getState());
    Object.keys(orderdItems).forEach((item: string) => {
      const founded = newPurcaseOrders.find(
        (element: PurchaseElementData) => element.purchaseElementId === item
      );

      if (founded) {
        dispatch(
          change(formId, item, {
            ...orderdItems[item],
            transactionId: founded.id,
          })
        );
      }
    });
  };
