import { Dispatch, GetState } from 'store/utils/actions';
import { getlanguageCode } from 'utils/Configurator';

import { SelfServiceConfigurationApi } from '@gss/api/KioskApi';
import {
  BaseKioskConfigurationEntity,
  KioskStateDetails,
} from '@gss/api/KioskApi/entries';
import { API_HEADERS } from '@gss/configs/constants';
import { reduxStore } from '@gss/store';

import { getAllDistrictResults, getAllStateResults } from './selectors';
import types from './types';

export const fetchStates =
  (
    countryCode: string,
    languageCode: string = getlanguageCode(),
    skipCache: boolean = false
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: types.FETCH_STATES_REQUEST,
        payload: {
          key: countryCode,
        },
      });

      const states = (await SelfServiceConfigurationApi.getStates({
        pathParams: { code: countryCode },
        customConfig: {
          headers: { [API_HEADERS.acceptLanguage]: languageCode },
          skipCache,
        },
      })) as KioskStateDetails[];

      dispatch({
        type: types.FETCH_STATES_SUCCESS,
        payload: {
          key: countryCode,
          data: states,
        },
      });
    } catch (error) {
      dispatch({
        type: types.FETCH_STATES_FAILURE,
        payload: {
          key: countryCode,
          error,
        },
      });
    }
  };

export const fetchDistricts =
  (
    countryCode: string,
    languageCode: string = getlanguageCode(),
    skipCache: boolean = false
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: types.FETCH_DISTRICTS_REQUEST,
        payload: {
          key: countryCode,
        },
      });

      const dictionaries = (await SelfServiceConfigurationApi.getDistricts({
        pathParams: { code: countryCode },
        customConfig: {
          headers: { [API_HEADERS.acceptLanguage]: languageCode },
          skipCache,
        },
      })) as BaseKioskConfigurationEntity[];

      dispatch({
        type: types.FETCH_DISTRICTS_SUCCESS,
        payload: {
          key: countryCode,
          data: dictionaries,
        },
      });
    } catch (error) {
      dispatch({
        type: types.FETCH_DISTRICTS_FAILURE,
        payload: {
          key: countryCode,
          error,
        },
      });
    }
  };

export const refreshLazyDictionaryAfterLanguageChange = async (
  language: string
) => {
  const getState = reduxStore.getState;
  const dispatch = reduxStore.dispatch as Dispatch;

  const state = getState();
  const allStates = getAllStateResults(state);
  const allDistricts = getAllDistrictResults(state);

  const allCountryCodesForStates = Object.keys(allStates);
  const allCountryCodesForDistricts = Object.keys(allDistricts);

  await Promise.all([
    ...allCountryCodesForStates.map((code) =>
      dispatch(fetchStates(code, language, true))
    ),
    ...allCountryCodesForDistricts.map((code) =>
      dispatch(fetchDistricts(code, language, true))
    ),
  ]);
};
