const FETCH_RESERVATION_REQUEST = 'FETCH_RESERVATION_REQUEST';
const FETCH_RESERVATION_SUCCESS = 'FETCH_RESERVATION_SUCCESS';
const FETCH_RESERVATION_FAILURE = 'FETCH_RESERVATION_FAILURE';

const FETCH_RESERVATION_CHECK_IN_REQUEST = 'FETCH_RESERVATION_CHECK_IN_REQUEST';
const FETCH_RESERVATION_CHECK_IN_SUCCESS = 'FETCH_RESERVATION_CHECK_IN_SUCCESS';
const FETCH_RESERVATION_CHECK_IN_FAILURE = 'FETCH_RESERVATION_CHECK_IN_FAILURE';

const FETCH_RESERVATION_CHECK_OUT_REQUEST =
  'FETCH_RESERVATION_CHECK_OUT_REQUEST';
const FETCH_RESERVATION_CHECK_OUT_SUCCESS =
  'FETCH_RESERVATION_CHECK_OUT_SUCCESS';
const FETCH_RESERVATION_CHECK_OUT_FAILURE =
  'FETCH_RESERVATION_CHECK_OUT_FAILURE';

const FETCH_RESERVATION_GENERATE_KEYS_REQUEST =
  'FETCH_RESERVATION_GENERATE_KEYS_REQUEST';
const FETCH_RESERVATION_GENERATE_KEYS_SUCCESS =
  'FETCH_RESERVATION_GENERATE_KEYS_SUCCESS';
const FETCH_RESERVATION_GENERATE_KEYS_FAILURE =
  'FETCH_RESERVATION_GENERATE_KEYS_FAILURE';

const FETCH_BREAKDOWN_REQUEST = 'FETCH_BREAKDOWN_REQUEST';
const FETCH_BREAKDOWN_SUCCESS = 'FETCH_BREAKDOWN_SUCCESS';
const FETCH_BREAKDOWN_FAILURE = 'FETCH_BREAKDOWN_FAILURE';

const FETCH_SUGGESTED_NUMBER_REQUEST = 'FETCH_SUGGESTED_NUMBER_REQUEST';
const FETCH_SUGGESTED_NUMBER_SUCCESS = 'FETCH_SUGGESTED_NUMBER_SUCCESS';
const FETCH_SUGGESTED_NUMBER_FAILURE = 'FETCH_SUGGESTED_NUMBER_FAILURE';

const FETCH_SHARING_SUMMARY_REQUEST = 'FETCH_SHARING_SUMMARY_REQUEST';
const FETCH_SHARING_SUMMARY_SUCCESS = 'FETCH_SHARING_SUMMARY_SUCCESS';
const FETCH_SHARING_SUMMARY_FAILURE = 'FETCH_SHARING_SUMMARY_FAILURE';

const ASSIGN_ROOM_REQUEST = 'ASSIGN_ROOM_REQUEST';
const ASSIGN_ROOM_SUCCESS = 'ASSIGN_ROOM_SUCCESS';
const ASSIGN_ROOM_FAILURE = 'ASSIGN_ROOM_FAILURE';

const CHECK_IN_REQUEST = 'CHECK_IN_REQUEST';
const CHECK_IN_SUCCESS = 'CHECK_IN_SUCCESS';
const CHECK_IN_FAILURE = 'CHECK_IN_FAILURE';

const POST_CHECK_IN_LETTER_REQUEST = 'POST_CHECK_IN_LETTER_REQUEST';
const POST_CHECK_IN_LETTER_SUCCESS = 'POST_CHECK_IN_LETTER_SUCCESS';
const POST_CHECK_IN_LETTER_FAILURE = 'POST_CHECK_IN_LETTER_FAILURE';

const CUT_KEY_REQUEST = 'CUT_KEY_REQUEST';
const CUT_KEY_SUCCESS = 'CUT_KEY_SUCCESS';
const CUT_KEY_FAILURE = 'CUT_KEY_FAILURE';

const GET_CUT_KEY_STATUS_REQUEST = 'GET_CUT_KEY_STATUS_REQUEST';
const GET_CUT_KEY_STATUS_SUCCESS = 'GET_CUT_KEY_STATUS_SUCCESS';
const GET_CUT_KEY_STATUS_FAILURE = 'GET_CUT_KEY_STATUS_FAILURE';

const DUPLICATE_KEY_REQUEST = 'DUPLICATE_KEY_REQUEST';
const DUPLICATE_KEY_SUCCESS = 'DUPLICATE_KEY_SUCCESS';
const DUPLICATE_KEY_FAILURE = 'DUPLICATE_KEY_FAILURE';

const GET_DUPLICATE_KEY_STATUS_REQUEST = 'GET_DUPLICATE_KEY_STATUS_REQUEST';
const GET_DUPLICATE_KEY_STATUS_SUCCESS = 'GET_DUPLICATE_KEY_STATUS_SUCCESS';
const GET_DUPLICATE_KEY_STATUS_FAILURE = 'GET_DUPLICATE_KEY_STATUS_FAILURE';

const GET_ASSIGN_ROOM_STATUS_REQUEST = 'GET_ASSIGN_ROOM_STATUS_REQUEST';
const GET_ASSIGN_ROOM_STATUS_SUCCESS = 'GET_ASSIGN_ROOM_STATUS_SUCCESS';
const GET_ASSIGN_ROOM_STATUS_FAILURE = 'GET_ASSIGN_ROOM_STATUS_FAILURE';

const FETCH_RESERVATION_PURCHASES_REQUEST =
  'FETCH_RESERVATION_PURCHASES_REQUEST';
const FETCH_RESERVATION_PURCHASES_SUCCESS =
  'FETCH_RESERVATION_PURCHASES_SUCCESS';
const FETCH_RESERVATION_PURCHASES_FAILURE =
  'FETCH_RESERVATION_PURCHASES_FAILURE';

const UPDATE_RESERVATION_PURCHASES_REQUEST =
  'UPDATE_RESERVATION_PURCHASES_REQUEST';
const UPDATE_RESERVATION_PURCHASES_SUCCESS =
  'UPDATE_RESERVATION_PURCHASES_SUCCESS';
const UPDATE_RESERVATION_PURCHASES_FAILURE =
  'UPDATE_RESERVATION_PURCHASES_FAILURE';

const FETCH_RESERVATION_DETAILS_REQUEST = 'FETCH_RESERVATION_DETAILS_REQUEST';
const FETCH_RESERVATION_DETAILS_SUCCESS = 'FETCH_RESERVATION_DETAILS_SUCCESS';
const FETCH_RESERVATION_DETAILS_FAILURE = 'FETCH_RESERVATION_DETAILS_FAILURE';

const UPDATE_PURPOSE_OF_STAY_REQUEST = 'UPDATE_PURPOSE_OF_STAY_REQUEST';
const UPDATE_PURPOSE_OF_STAY_SUCCESS = 'UPDATE_PURPOSE_OF_STAY_SUCCESS';
const UPDATE_PURPOSE_OF_STAY_FAILURE = 'UPDATE_PURPOSE_OF_STAY_FAILURE';

const FETCH_RESERVATION_EXTENDED_REQUEST = 'FETCH_RESERVATION_EXTENDED_REQUEST';
const FETCH_RESERVATION_EXTENDED_SUCCESS = 'FETCH_RESERVATION_EXTENDED_SUCCESS';
const FETCH_RESERVATION_EXTENDED_FAILURE = 'FETCH_RESERVATION_EXTENDED_FAILURE';

const FULL_CHECK_IN_REQUEST = 'FULL_CHECK_IN_REQUEST';
const FULL_CHECK_IN_SUCCESS = 'FULL_CHECK_IN_SUCCESS';
const FULL_CHECK_IN_FAILURE = 'FULL_CHECK_IN_FAILURE';

const UPDATE_RESERVATION_PROFILE_REQUEST = 'UPDATE_RESERVATION_PROFILE_REQUEST';
const UPDATE_RESERVATION_PROFILE_SUCCESS = 'UPDATE_RESERVATION_PROFILE_SUCCESS';
const UPDATE_RESERVATION_PROFILE_FAILURE = 'UPDATE_RESERVATION_PROFILE_FAILURE';

const FETCH_MULTIROOM_SEGMENT_REQUEST = 'FETCH_MULTIROOM_SEGMENT_REQUEST';
const FETCH_MULTIROOM_SEGMENT_SUCCESS = 'FETCH_MULTIROOM_SEGMENT_SUCCESS';
const FETCH_MULTIROOM_SEGMENT_FAILURE = 'FETCH_MULTIROOM_SEGMENT_FAILURE';

const FETCH_MULTIROOM_RESERVATION_LIST_REQUEST =
  'FETCH_MULTIROOM_RESERVATION_LIST_REQUEST';
const FETCH_MULTIROOM_RESERVATION_LIST_SUCCESS =
  'FETCH_MULTIROOM_RESERVATION_LIST_SUCCESS';
const FETCH_MULTIROOM_RESERVATION_LIST_FAILURE =
  'FETCH_MULTIROOM_RESERVATION_LIST_FAILURE';

const CLEAR_RESERVATION_ERRORS = 'CLEAR_RESERVATION_ERRORS';

const CHOOSE_RESERVATION = 'CHOOSE_RESERVATION';

export default {
  FETCH_RESERVATION_REQUEST,
  FETCH_RESERVATION_SUCCESS,
  FETCH_RESERVATION_FAILURE,
  FETCH_RESERVATION_CHECK_IN_REQUEST,
  FETCH_RESERVATION_CHECK_IN_SUCCESS,
  FETCH_RESERVATION_CHECK_IN_FAILURE,
  FETCH_RESERVATION_CHECK_OUT_REQUEST,
  FETCH_RESERVATION_CHECK_OUT_SUCCESS,
  FETCH_RESERVATION_CHECK_OUT_FAILURE,
  FETCH_BREAKDOWN_REQUEST,
  FETCH_BREAKDOWN_SUCCESS,
  FETCH_BREAKDOWN_FAILURE,
  FETCH_SUGGESTED_NUMBER_REQUEST,
  FETCH_SUGGESTED_NUMBER_SUCCESS,
  FETCH_SUGGESTED_NUMBER_FAILURE,
  FETCH_SHARING_SUMMARY_REQUEST,
  FETCH_SHARING_SUMMARY_SUCCESS,
  FETCH_SHARING_SUMMARY_FAILURE,
  ASSIGN_ROOM_REQUEST,
  ASSIGN_ROOM_SUCCESS,
  ASSIGN_ROOM_FAILURE,
  CHECK_IN_REQUEST,
  CHECK_IN_SUCCESS,
  CHECK_IN_FAILURE,
  POST_CHECK_IN_LETTER_REQUEST,
  POST_CHECK_IN_LETTER_SUCCESS,
  POST_CHECK_IN_LETTER_FAILURE,
  CUT_KEY_REQUEST,
  CUT_KEY_SUCCESS,
  CUT_KEY_FAILURE,
  GET_CUT_KEY_STATUS_REQUEST,
  GET_CUT_KEY_STATUS_SUCCESS,
  GET_CUT_KEY_STATUS_FAILURE,
  DUPLICATE_KEY_REQUEST,
  DUPLICATE_KEY_SUCCESS,
  DUPLICATE_KEY_FAILURE,
  GET_DUPLICATE_KEY_STATUS_REQUEST,
  GET_DUPLICATE_KEY_STATUS_SUCCESS,
  GET_DUPLICATE_KEY_STATUS_FAILURE,
  GET_ASSIGN_ROOM_STATUS_REQUEST,
  GET_ASSIGN_ROOM_STATUS_SUCCESS,
  GET_ASSIGN_ROOM_STATUS_FAILURE,
  FETCH_RESERVATION_PURCHASES_REQUEST,
  FETCH_RESERVATION_PURCHASES_SUCCESS,
  FETCH_RESERVATION_PURCHASES_FAILURE,
  UPDATE_RESERVATION_PURCHASES_REQUEST,
  UPDATE_RESERVATION_PURCHASES_SUCCESS,
  UPDATE_RESERVATION_PURCHASES_FAILURE,
  FETCH_RESERVATION_DETAILS_REQUEST,
  FETCH_RESERVATION_DETAILS_SUCCESS,
  FETCH_RESERVATION_DETAILS_FAILURE,
  UPDATE_PURPOSE_OF_STAY_REQUEST,
  UPDATE_PURPOSE_OF_STAY_SUCCESS,
  UPDATE_PURPOSE_OF_STAY_FAILURE,
  FETCH_RESERVATION_GENERATE_KEYS_REQUEST,
  FETCH_RESERVATION_GENERATE_KEYS_SUCCESS,
  FETCH_RESERVATION_GENERATE_KEYS_FAILURE,
  FULL_CHECK_IN_REQUEST,
  FULL_CHECK_IN_SUCCESS,
  FULL_CHECK_IN_FAILURE,
  CLEAR_RESERVATION_ERRORS,
  CHOOSE_RESERVATION,
  FETCH_RESERVATION_EXTENDED_REQUEST,
  FETCH_RESERVATION_EXTENDED_SUCCESS,
  FETCH_RESERVATION_EXTENDED_FAILURE,
  UPDATE_RESERVATION_PROFILE_REQUEST,
  UPDATE_RESERVATION_PROFILE_SUCCESS,
  UPDATE_RESERVATION_PROFILE_FAILURE,
  FETCH_MULTIROOM_SEGMENT_REQUEST,
  FETCH_MULTIROOM_SEGMENT_SUCCESS,
  FETCH_MULTIROOM_SEGMENT_FAILURE,
  FETCH_MULTIROOM_RESERVATION_LIST_REQUEST,
  FETCH_MULTIROOM_RESERVATION_LIST_SUCCESS,
  FETCH_MULTIROOM_RESERVATION_LIST_FAILURE,
};
