import LazyLoadedDictionaryStore, {
  DictionaryStatuses,
  StateChangeAction,
} from '../interfaces';

export const updateDictionaryStatusData = <
  StateType extends keyof LazyLoadedDictionaryStore
>(
  state: LazyLoadedDictionaryStore,
  action: StateChangeAction,
  stateType: StateType,
  status: DictionaryStatuses
): LazyLoadedDictionaryStore => {
  const { key, data } = action;

  return {
    ...state,
    [stateType]: {
      ...state[stateType],
      [key]: {
        ...state[stateType]?.[key],
        status,
        data,
      },
    },
  };
};
