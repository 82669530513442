import { api } from 'config';
import { CALL_API } from 'store/middleware/api';

import types from './types';

export const fetchMinibarItems = (startDate: string, endDate: string) => ({
  [CALL_API]: {
    endpoint: api.AVAILABILITY.PURCHASE_ELEMENTS,
    params: {
      stayFromInclusive: startDate,
      stayToExclusive: endDate,
    },
    types: [
      types.FETCH_MINIBAR_ITEMS_REQUEST,
      types.FETCH_MINIBAR_ITEMS_SUCCESS,
      types.FETCH_MINIBAR_ITEMS_FAILURE,
    ],
  },
});

export const fetchCurrentDate = (startDate: string, endDate: string) => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.CURRENT_DATE,
    types: [
      types.FETCH_CURRENT_DATE_REQUEST,
      types.FETCH_CURRENT_DATE_SUCCESS,
      types.FETCH_CURRENT_DATE_FAILURE,
    ],
  },
});
