const FETCH_STATES_REQUEST = 'FETCH_STATES_REQUEST';
const FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS';
const FETCH_STATES_FAILURE = 'FETCH_STATES_FAILURE';

const FETCH_DISTRICTS_REQUEST = 'FETCH_DISTRICTS_REQUEST';
const FETCH_DISTRICTS_SUCCESS = 'FETCH_DISTRICTS_SUCCESS';
const FETCH_DISTRICTS_FAILURE = 'FETCH_DISTRICTS_FAILURE';

export default {
  FETCH_STATES_REQUEST,
  FETCH_STATES_SUCCESS,
  FETCH_STATES_FAILURE,
  FETCH_DISTRICTS_REQUEST,
  FETCH_DISTRICTS_SUCCESS,
  FETCH_DISTRICTS_FAILURE,
};
