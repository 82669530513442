/* eslint-disable max-len */
import { api } from 'config';
import { CALL_API } from 'store/middleware/api';
import { Dispatch, GetState } from 'store/utils/actions';
import { Configurator } from 'utils';
import { getlanguageCode } from 'utils/Configurator';

import {
  buildFIQLFilter,
  combineFilters,
  FIQLOperators,
} from '@ac/library-api';

import { getInterfaceCashiers } from './selectors';
import types from './types';

export const fetchMembershipLevels = () => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.MEMBERSHIP_LEVEL,
    paginate: true,
    types: [
      types.FETCH_MEMBERSHIP_LEVELS_REQUEST,
      types.FETCH_MEMBERSHIP_LEVELS_SUCCESS,
      types.FETCH_MEMBERSHIP_LEVELS_FAILURE,
    ],
  },
});

export const fetchMembershipTypes = () => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.MEMBERSHIP_TYPE,
    paginate: true,
    types: [
      types.FETCH_MEMBERSHIP_TYPES_REQUEST,
      types.FETCH_MEMBERSHIP_TYPES_SUCCESS,
      types.FETCH_MEMBERSHIP_TYPES_FAILURE,
    ],
  },
});

export const fetchRoomAttributeTypes = () => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.ROOM_ATTRIBUTES,
    paginate: true,
    types: [
      types.FETCH_ATTRIBUTE_TYPES_REQUEST,
      types.FETCH_ATTRIBUTE_TYPES_SUCCESS,
      types.FETCH_ATTRIBUTE_TYPES_FAILURE,
    ],
  },
});

export const fetchProfileTypes = () => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.PROFILE_TYPE,
    paginate: true,
    types: [
      types.FETCH_PROFILE_TYPES_REQUEST,
      types.FETCH_PROFILE_TYPES_SUCCESS,
      types.FETCH_PROFILE_TYPES_FAILURE,
    ],
  },
});

export const fetchPropertyFloors = () => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.PROPERTY_FLOORS,
    paginate: true,
    types: [
      types.FETCH_PROPERTY_FLOORS_REQUEST,
      types.FETCH_PROPERTY_FLOORS_SUCCESS,
      types.FETCH_PROPERTY_FLOORS_FAILURE,
    ],
  },
});

export const fetchRoomLocations = () => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.ROOM_LOCATIONS,
    paginate: true,
    types: [
      types.FETCH_ROOM_LOCATIONS_REQUEST,
      types.FETCH_ROOM_LOCATIONS_SUCCESS,
      types.FETCH_ROOM_LOCATIONS_FAILURE,
    ],
  },
});

export const fetchTransactionCodes = () => {
  const filter = combineFilters([
    buildFIQLFilter('isActive', FIQLOperators.equal, true),
    buildFIQLFilter('isPostingAllowed', FIQLOperators.equal, true),
  ]);

  return {
    [CALL_API]: {
      endpoint: `${api.CONFIGURATION.TRANSACTION_CODES}?filter=${filter}&sort=displaySequence`,
      paginate: true,
      types: [
        types.FETCH_TRANSACTION_CODES_REQUEST,
        types.FETCH_TRANSACTION_CODES_SUCCESS,
        types.FETCH_TRANSACTION_CODES_FAILURE,
      ],
    },
  };
};

export const clearEntitiesErrors = () => ({
  type: types.CLEAR_ENTITIES_ERRORS,
});

export const fetchKioskTransactionCode = () => {
  const {
    entityCodes: { TRANSACTION_CODE_FOR_CREDIT_CARD },
  } = Configurator;
  const transactionCodeId = Configurator.getEntityTypeId(
    TRANSACTION_CODE_FOR_CREDIT_CARD
  );
  const filter = buildFIQLFilter('id', FIQLOperators.equal, transactionCodeId);

  return {
    [CALL_API]: {
      endpoint: `${api.CONFIGURATION.TRANSACTION_CODES}?filter=${filter}`,
      types: [
        types.FETCH_KIOSK_TRANSACTION_CODE_REQUEST,
        types.FETCH_KIOSK_TRANSACTION_CODE_SUCCESS,
        types.FETCH_KIOSK_TRANSACTION_CODE_FAILURE,
      ],
    },
  };
};

export const fetchIfcCashiers = () => ({
  [CALL_API]: {
    endpoint: `${api.CONFIGURATION.CASHIERS}?filter=isActive==true;type==IFC`,
    paginate: true,
    types: [
      types.FETCH_INTERFACE_CASHIERS_REQUEST,
      types.FETCH_INTERFACE_CASHIERS_SUCCESS,
      types.FETCH_INTERFACE_CASHIERS_FAILURE,
    ],
  },
});

export const fetchIfcCashierNumber =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.FETCH_CASHIER_NUMBER_REQUEST });
      await dispatch(fetchIfcCashiers());

      const state = getState();
      const cashierId = Configurator.getEntityTypeId(
        Configurator.entityCodes.KIOSK_IFC_CASHIER_NUMBER
      );
      const interfaceCashiers = getInterfaceCashiers(state);
      const cashier = interfaceCashiers.find(
        (cashier) => cashier.id === cashierId
      );

      return dispatch({
        type: types.FETCH_CASHIER_NUMBER_SUCCESS,
        payload: cashier,
      });
    } catch (error) {
      return dispatch({
        type: types.FETCH_CASHIER_NUMBER_FAILURE,
        payload: error,
      });
    }
  };

export const fetchFolioStyleCode =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const folioStyleId = Configurator.getEntityTypeId(
      Configurator.entityCodes.DEFAULT_FOLIO_STYLE
    );

    return (
      folioStyleId &&
      dispatch({
        [CALL_API]: {
          endpoint: `${api.CONFIGURATION.FOLIO_STYLE}?filter=id==${folioStyleId}`,
          paginate: true,
          types: [
            types.FETCH_DEFAULT_FOLIO_STYLE_REQUEST,
            types.FETCH_DEFAULT_FOLIO_STYLE_SUCCESS,
            types.FETCH_DEFAULT_FOLIO_STYLE_FAILURE,
          ],
        },
      })
    );
  };

export const fetchTaxRules = () => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.TAX_RULES,
    paginate: true,
    types: [
      types.FETCH_TAX_RULES_REQUEST,
      types.FETCH_TAX_RULES_SUCCESS,
      types.FETCH_TAX_RULES_FAILURE,
    ],
  },
});

export const fetchFolioStyles = () => ({
  [CALL_API]: {
    endpoint: `${api.CONFIGURATION.FOLIO_STYLE}?filter=guestFacing==true`,
    paginate: true,
    types: [
      types.FETCH_FOLIO_STYLES_REQUEST,
      types.FETCH_FOLIO_STYLES_SUCCESS,
      types.FETCH_FOLIO_STYLES_FAILURE,
    ],
  },
});

export const fetchCreditCardAuthorizationRules = (
  languageCode: string = getlanguageCode()
) => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.CREDIT_CARD_AUTHORIZATION_RULES,
    headers: { 'Accept-Language': languageCode },
    payload: { languageCode },
    types: [
      types.FETCH_CREDIT_CARD_AUTHORIZATION_RULE_REQUEST,
      types.FETCH_CREDIT_CARD_AUTHORIZATION_RULE_SUCCESS,
      types.FETCH_CREDIT_CARD_AUTHORIZATION_RULE_FAILURE,
    ],
  },
});
