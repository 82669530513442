import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal } from 'components';
import { compose } from 'redux';
import { getEmailToSendInvoice } from 'store/cashiering/selectors';
import {
  getAllDistrictResults,
  getAllStateResults,
} from 'store/lazyLoadedDictionary/selectors';
import { getAllCompaniesDetails } from 'store/profile/selectors';
import { getFeatureToggles, getIsDistrictEnabled } from 'store/setup/selectors';
import { ChargesFolio } from 'types/Api/Cashiering';
import { Address, CommunicationChannel } from 'types/Api/Profile';
import Store from 'types/Store';
import { StoredFeatureToggles } from 'types/Store/SetupStore';
import { Configurator, stringifyAddressData } from 'utils';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  BaseKioskConfigurationEntity,
  KioskStateDetails,
} from '@gss/api/KioskApi/entries';
import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './InvoiceDetailsPanel.style';
import { TaxIDSection } from './TaxIDSection';

interface PassedProps {
  name: string;
  profileAddress: Address;
  communicationChannels: CommunicationChannel[];
  folios: ChargesFolio[];
  email: string;
  currentFolioNumber: number;
  isPaymentConfirmationScreen?: boolean;
  profileId?: string;
  onTaxIdChange?: (taxId: string | undefined) => void;
}

interface InvoiceDetailsPanelProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {
  onSubmit: (values: any) => void;
  emailsToSendInvoice: string[];
  states: Record<string, KioskStateDetails[]>;
  districts: Record<string, BaseKioskConfigurationEntity[]>;
  featureToggles: StoredFeatureToggles;
  isDistrictEnabled: boolean;
  allCompaniesDetails: Array<{
    companyName: string;
    taxId: string;
    id: string;
  }>;
}

interface InvoiceDetailsPanelState {
  isModalOpen: boolean;
}

class InvoiceDetailsPanel extends PureComponent<
  InvoiceDetailsPanelProps,
  InvoiceDetailsPanelState
> {
  public static defaultProps = {
    profileAddress: {
      addressLine1: '',
      addressLine2: '',
      postCode: '',
      city: '',
      countryCode: '',
    },
    name: '',
    isPaymentConfirmationScreen: false,
  };

  public state = {
    isModalOpen: false,
  };

  public render() {
    const {
      classes,
      name,
      t,
      profileAddress: { addressLine1, addressLine2 },
      emailsToSendInvoice,
      email,
      isPaymentConfirmationScreen,
      currentFolioNumber,
      profileId,
      allCompaniesDetails,
      onTaxIdChange,
    } = this.props;

    const { isModalOpen } = this.state;
    const companyFolio = allCompaniesDetails.find(
      (company: { companyName: string; taxId: string; id: string }) =>
        company.id === profileId
    );

    return (
      <Grid className={classes.wrapper}>
        <Modal
          type="editEmail"
          isOpen={isModalOpen}
          onCancel={this.closeEditEmailModal}
          className={classes.modalWrapper}
          displayedEmail={email}
          currentFolioNumber={currentFolioNumber}
        />
        {!isPaymentConfirmationScreen && (
          <>
            <Typography className={classes.subtitle}>{name}</Typography>
            {companyFolio?.taxId && (
              <Typography className={classes.subtitle}>
                {companyFolio.taxId}
              </Typography>
            )}
            <Typography className={classes.subtitle}>{addressLine1}</Typography>
            {addressLine2 ? (
              <Typography className={classes.subtitle}>
                {addressLine2}
              </Typography>
            ) : null}
            <Typography className={classes.subtitle}>
              {this.thirdLineOfAddress}
            </Typography>
          </>
        )}
        <Typography className={classes.emailTitle}>
          {t('EMAIL_TO_SEND_FISCAL_DOCUMENT')}
        </Typography>
        {!emailsToSendInvoice.length && (
          <Typography className={classes.subtitle}>{email}</Typography>
        )}
        {emailsToSendInvoice.map((email: string) => (
          <Typography className={classes.singleEmail} key={email}>
            {email}
          </Typography>
        ))}
        <Typography className={classes.link} onClick={this.openEditEmailModal}>
          {t('ADD_OR_EDIT_EMAIL')}
        </Typography>

        {this.isTaxIdEnabled && (
          <TaxIDSection
            taxId={this.currentFolio?.profile?.details?.taxId}
            onTaxIdChange={onTaxIdChange}
            className="spacing-top-lg"
          />
        )}
      </Grid>
    );
  }

  private get currentFolio() {
    const { currentFolioNumber, folios } = this.props;
    if (!folios || !isDefined(currentFolioNumber)) {
      return undefined;
    }

    return folios[currentFolioNumber];
  }

  private get isIndividualProfileFolio() {
    if (!this.currentFolio) return false;
    const profileRoleCode = this.currentFolio.profileRoleCode?.code;

    return profileRoleCode === Configurator.profileRoles.INDIVIDUAL;
  }

  private get isTaxIdEnabled() {
    const { isPaymentConfirmationScreen, featureToggles } = this.props;

    return (
      isPaymentConfirmationScreen &&
      this.isIndividualProfileFolio &&
      Configurator.getSwitch(Configurator.switchCodes.SHOW_TAX_ID) &&
      featureToggles.IndividualTaxId
    );
  }

  private get thirdLineOfAddress(): string {
    const {
      i18n,
      states,
      districts,
      isDistrictEnabled,
      profileAddress: {
        addressLine1,
        addressLine2,
        ...restOfAddressProperties
      },
    } = this.props;

    return stringifyAddressData(
      restOfAddressProperties,
      states,
      districts,
      i18n.language,
      isDistrictEnabled
    );
  }

  private openEditEmailModal = () => this.setState({ isModalOpen: true });

  private closeEditEmailModal = () => this.setState({ isModalOpen: false });
}

const mapStateToProps = (state: Store) => ({
  emailsToSendInvoice: getEmailToSendInvoice(state),
  allCompaniesDetails: getAllCompaniesDetails(state),
  featureToggles: getFeatureToggles(state),
  states: getAllStateResults(state),
  districts: getAllDistrictResults(state),
  isDistrictEnabled: getIsDistrictEnabled(state),
});

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(InvoiceDetailsPanel) as (props: PassedProps) => JSX.Element;
