import { AxiosResponse } from 'axios';
import { api } from 'config';
import i18next from 'i18next';
import { fetchFolio } from 'store/cashiering/folio/actions';
import {
  getAddTransactionOperation,
  getAllDetailedFolios,
  getAllFolios,
  getAuthorizationDetails,
  getBillingInstructions,
  getCashieringAccount,
  getCashieringErrors,
  getChargeReservationOperation,
  getCheckOutFolioOperation,
  getCheckOutOperation,
  getFolios,
  getFoliosForCheckOut,
  getUpdateAuthorizationOperation,
  getVoidAuthorizationOperation,
  getWrongVersionedFolioIds,
  isFetching,
  shouldLeaveOpenAccount,
} from 'store/cashiering/selectors';
import {
  fetchDistricts,
  fetchStates,
} from 'store/lazyLoadedDictionary/actions';
import { CALL_API, CallApiAction } from 'store/middleware/api';
import { getProfile } from 'store/profile/selectors';
import { getAccountId, getReservation } from 'store/reservation/selectors';
import { getTitle } from 'store/selectors';
import { Dispatch, GetState } from 'store/utils/actions';
import { ApiErrorCode } from 'types/Api/ApiErrorCode';
import {
  ChargesFolio,
  CreateBillingInstructionDataModel,
  CreateFolioDataModel,
  CreatePaymentMethodDataModel,
  CreateTransactionModel,
  CreateTransactionOptions,
  CreditCardOperationStatusResponse,
  Folio,
} from 'types/Api/Cashiering';
import { ApiError } from 'types/Api/Shared';
import { Api, Configurator } from 'utils';

import {
  getIfMatchConfig,
  PatchFolio,
  PatchIndividualDetailsRequest,
} from '@ac/library-api';

import {
  getPaymentDevice,
  getWorkstationId,
} from '@gss/store/configuration/selectors';

import types from './types';

const getDefaultsForAddingTransaction = (
  terminalId: string = '',
  workstationId: string = ''
): CreateTransactionOptions => ({
  applyBillingInstructions: false,
  cashierNumber: Configurator.cashieringSettings.cashierNumber,
  checkNumber: '',
  comment: '',
  folioText: '',
  isCommentInternal: false,
  remarks: '',
  terminalId,
  workstationId,
});

export const refetchFolio = async (
  response: AxiosResponse,
  folioId: string,
  dispatch: Dispatch
) => {
  const isVersionCorrect = response.status !== 412;
  if (isVersionCorrect) return true;
  await dispatch(fetchFolio(folioId));

  return false;
};

export const refetchFolioProfile = async (
  response: AxiosResponse,
  profileId: string,
  folioId: string,
  dispatch: Dispatch
) => {
  const { status: operationStatus } = response.data;
  if (operationStatus.code === ApiErrorCode.OptimisticConcurrency) return true;
  await dispatch(fetchFolioProfile(profileId, folioId));

  return false;
};

export const checkOut =
  (options?: { leaveOpenAccount: boolean }) =>
  (dispatch: Dispatch, getState: GetState) => {
    const account = getCashieringAccount(getState());
    const leaveOpenAccount = options
      ? Boolean(options.leaveOpenAccount)
      : Configurator.cashieringSettings.leaveOpenAccount;
    const data = {
      leaveOpenAccount,
      cashierNumber: Configurator.cashieringSettings.cashierNumber,
    };

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.CHECK_OUT(account.id),
        data,
        checkFailureCondition: async (response: AxiosResponse) => {
          const account = getCashieringAccount(getState());
          const isVersionCorrect = response.status !== 412;
          if (isVersionCorrect) {
            const {
              details: [actionResponse],
            } = response.data;

            return (
              actionResponse.code !==
              Configurator.checkOutCodes.NOT_ALL_FOLIOS_CHECKED_OUT
            );
          }

          await dispatch(fetchCashieringAccount(account.id));

          return false;
        },
        method: 'POST',
        getOptions: () => {
          const account = getCashieringAccount(getState());

          return {
            headers: {
              'If-Match': account.version,
            },
          };
        },
        types: [
          types.CHECK_OUT_REQUEST,
          types.CHECK_OUT_SUCCESS,
          types.CHECK_OUT_FAILURE,
        ],
      },
    });
  };

export const checkOutFolio =
  ({ id }: { id: string; version: number }) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const accountId = getAccountId(getState());

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.CHECK_OUT_FOLIO(accountId, id),
        data: {
          cashierNumber: Configurator.cashieringSettings.cashierNumber,
          forceCheckOutFolioWindowWithBillingInstructions:
            Configurator.cashieringSettings.forceCheckOutFolio,
          emails: {},
        },
        payload: id,
        method: 'POST',
        checkFailureCondition: (response: AxiosResponse) =>
          refetchFolio(response, id, dispatch),
        getOptions: () => {
          const folios = getFolios(getState());
          const folioWithSpecifiedId = folios.find((item) => item.id === id);

          return {
            headers: {
              'If-Match': folioWithSpecifiedId?.version,
            },
          };
        },
        types: [
          types.CHECK_OUT_FOLIO_REQUEST,
          types.CHECK_OUT_FOLIO_SUCCESS,
          types.CHECK_OUT_FOLIO_FAILURE,
        ],
      },
    });
  };

export const getCheckOutFolioStatus = (operationId: string) => ({
  [CALL_API]: {
    endpoint: api.CASHIERING.CHECK_OUT_FOLIO_STATUS(operationId),
    payload: operationId,
    checkCondition: (response: AxiosResponse) => {
      const { operationStatus } = response.data.status;

      return operationStatus === Configurator.operationStatuses.COMPLETED;
    },
    types: [
      types.FETCH_CHECK_OUT_FOLIO_STATUS_REQUEST,
      types.FETCH_CHECK_OUT_FOLIO_STATUS_SUCCESS,
      types.FETCH_CHECK_OUT_FOLIO_STATUS_FAILURE,
    ],
  },
});

export const fetchFolios = () => (dispatch: Dispatch, getState: GetState) => {
  const accountId = getAccountId(getState());

  return dispatch({
    [CALL_API]: {
      endpoint: api.CASHIERING.FOLIOS(accountId),
      paginate: true,
      types: [
        types.FETCH_FOLIOS_REQUEST,
        types.FETCH_FOLIOS_SUCCESS,
        types.FETCH_FOLIOS_FAILURE,
      ],
    },
  });
};

export const fetchCashieringAccount = (accountId: string) => ({
  [CALL_API]: {
    endpoint: api.CASHIERING.ACCOUNT(accountId),
    types: [
      types.FETCH_CASHIERING_ACCOUNT_REQUEST,
      types.FETCH_CASHIERING_ACCOUNT_SUCCESS,
      types.FETCH_CASHIERING_ACCOUNT_FAILURE,
    ],
  },
});

export const getCheckOutStatus =
  () => (dispatch: Dispatch, getState: GetState) => {
    const operation = getCheckOutOperation(getState());
    const { id: operationId } = operation;

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.CHECK_OUT_STATUS(operationId),
        checkCondition: (response: AxiosResponse) => {
          const { operationStatus } = response.data.status;

          return operationStatus === Configurator.operationStatuses.COMPLETED;
        },
        types: [
          types.FETCH_CHECK_OUT_STATUS_REQUEST,
          types.FETCH_CHECK_OUT_STATUS_SUCCESS,
          types.FETCH_CHECK_OUT_STATUS_FAILURE,
        ],
      },
    });
  };

export const addCompanyFolio = (
  accountId: string,
  data: CreateFolioDataModel
): CallApiAction => ({
  [CALL_API]: {
    data,
    repetitions: 5,
    endpoint: api.CASHIERING.FOLIOS(accountId),
    method: 'POST',
    checkCondition: (response: AxiosResponse) => Api.isSuccessRequest(response),
    interval: 500,
    types: [
      types.ADD_COMPANY_FOLIO_REQUEST,
      types.ADD_COMPANY_FOLIO_SUCCESS,
      types.ADD_COMPANY_FOLIO_FAILURE,
    ],
  },
});

export const fetchBillingInstructions = (accountId: string) => ({
  [CALL_API]: {
    endpoint: api.CASHIERING.BILLING_INSTRUCTIONS(accountId),
    paginate: true,
    types: [
      types.FETCH_BILLING_INSTRUCTIONS_REQUEST,
      types.FETCH_BILLING_INSTRUCTIONS_SUCCESS,
      types.FETCH_BILLING_INSTRUCTIONS_FAILURE,
    ],
  },
});

export const addBillingInstruction = (
  accountId: string,
  data: CreateBillingInstructionDataModel
): CallApiAction => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: api.CASHIERING.BILLING_INSTRUCTIONS(accountId),
    types: [
      types.ADD_BILLING_INSTRUCTION_REQUEST,
      types.ADD_BILLING_INSTRUCTION_SUCCESS,
      types.ADD_BILLING_INSTRUCTION_FAILURE,
    ],
  },
});

export const applyBillingInstruction = (accountId: string): CallApiAction => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: api.CASHIERING.APPLY_BILLING_INSTRUCTIONS(accountId),
    types: [
      types.APPLY_BILLING_INSTRUCTIONS_REQUEST,
      types.APPLY_BILLING_INSTRUCTIONS_SUCCESS,
      types.APPLY_BILLING_INSTRUCTIONS_FAILURE,
    ],
  },
});

export const getApplyBillingInstructionStatus =
  () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const { operationId } = getBillingInstructions(state);

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.APPLY_BILLING_INSTRUCTIONS_STATUS(operationId),
        checkCondition: (response: AxiosResponse) => {
          const { operationStatus } = response.data.status;

          return operationStatus === Configurator.operationStatuses.COMPLETED;
        },
        types: [
          types.FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_REQUEST,
          types.FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_SUCCESS,
          types.FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_FAILURE,
        ],
      },
    });
  };

export const addPaymentMethod = (
  accountId: string,
  data: CreatePaymentMethodDataModel
) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: api.CASHIERING.PAYMENT_METHODS(accountId),
    types: [
      types.ADD_PAYMENT_METHOD_REQUEST,
      types.ADD_PAYMENT_METHOD_SUCCESS,
      types.ADD_PAYMENT_METHOD_FAILURE,
    ],
  },
});

const addingPaymentFailed = (status: string) => {
  const statuses = Configurator.paymentMethodsOperationStatuses;

  return (
    status === statuses.PaymentRejected || status === statuses.ProcessingFailed
  );
};

const getErrorFromFailedPaymentStatus = (
  responseData: CreditCardOperationStatusResponse
): ApiError => ({
  code: responseData.status,
  message: (responseData.errors || []).join('; '),
});

export const getAddPaymentMethodOperationStatus =
  (operationUrl: string) => (dispatch: Dispatch, getState: GetState) => {
    dispatch({
      [CALL_API]: {
        endpoint: operationUrl,
        repetitions: 180,
        interval: 1000,
        checkCondition: (response: AxiosResponse) => {
          const {
            data,
            data: { status },
          } = response;
          if (addingPaymentFailed(status)) {
            throw getErrorFromFailedPaymentStatus(data);
          }
          if (!isFetching(getState())) {
            Api.interruptInterval();
          }

          return (
            status ===
            Configurator.paymentMethodsOperationStatuses.PaymentCompleted
          );
        },
        types: [
          types.FETCH_PAYMENT_METHOD_STATUS_REQUEST,
          types.FETCH_PAYMENT_METHOD_STATUS_SUCCESS,
          types.FETCH_PAYMENT_METHOD_STATUS_FAILURE,
        ],
      },
    });
  };

export const updateFolioProfile = (
  accountId: string,
  { version, id }: { version: number; id: string },
  targetProfileId: string
): CallApiAction => ({
  [CALL_API]: {
    endpoint: api.CASHIERING.CHANGE_FOLIO_PROFILE(accountId, id),
    data: {
      profileId: targetProfileId,
    },
    method: 'POST',
    headers: {
      'If-Match': version,
    },
    types: [
      types.UPDATE_FOLIO_PROFILE_REQUEST,
      types.UPDATE_FOLIO_PROFILE_SUCCESS,
      types.UPDATE_FOLIO_PROFILE_FAILURE,
    ],
  },
});

export const sendInvoiceEmail =
  (folioId: string, emailAddresses: string[]) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const cashieringAccount = getCashieringAccount(state);
    const profile = getProfile(state);
    const title = getTitle(state);

    const { firstName, lastName, greeting } = profile.details;

    const { id: accountId } = cashieringAccount;

    const data = {
      sendCopy: false,
      emailMetadata: {
        emailAddresses,
        fullName: `${firstName} ${lastName}`,
        title,
        greeting,
      },
      processId: null,
    };

    return dispatch({
      [CALL_API]: {
        data,
        endpoint: api.CASHIERING.INVOICE_EMAIL(accountId, folioId),
        method: 'POST',
        payload: emailAddresses,
        repetitions: 5,
        checkFailureCondition: (response: AxiosResponse) => {
          return Api.isSuccessRequest(response);
        },
        interval: 5000,
        types: [
          types.SEND_INVOICE_EMAIL_REQUEST,
          types.SEND_INVOICE_EMAIL_SUCCESS,
          types.SEND_INVOICE_EMAIL_FAILURE,
        ],
      },
    });
  };

export const sendFinalInvoiceMail =
  (folioId: string) => (dispatch: Dispatch, getState: GetState) => {
    const cashieringAccount = getCashieringAccount(getState());
    const { id: accountId } = cashieringAccount;

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.INVOICE_FINAL(accountId, folioId),
        types: [
          types.SEND_FINAL_INVOICE_MAIL_REQUEST,
          types.SEND_FINAL_INVOICE_MAIL_SUCCESS,
          types.SEND_FINAL_INVOICE_MAIL_FAILURE,
        ],
      },
    });
  };

export const fetchFolioTransactions =
  (folioId: string) => (dispatch: Dispatch, getState: GetState) => {
    const cashieringAccount = getCashieringAccount(getState());
    const { id: accountId } = cashieringAccount;

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.TRANSACTIONS(accountId, folioId),
        payload: folioId,
        types: [
          types.FETCH_FOLIO_TRANSACTIONS_REQUEST,
          types.FETCH_FOLIO_TRANSACTIONS_SUCCESS,
          types.FETCH_FOLIO_TRANSACTIONS_FAILURE,
        ],
      },
    });
  };

const getFolioProfileAddressesEndpoint = (type: string, id: string) => {
  const {
    folioTypeCodes: { COMPANY, TRAVEL_AGENT },
  } = Configurator;
  if (type === COMPANY) return api.PROFILES.COMPANY(id);
  if (type === TRAVEL_AGENT) return api.PROFILES.TRAVEL_AGENT(id);

  return api.PROFILES.PROFILE(id);
};

export const fetchFolioProfile =
  (profileId: string, folioId: string) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const folios = getFolios(state);
    const folio = folios.find((folio) => folio.id === folioId);
    const {
      folioTypeCode: { code },
    } = folio!;
    const endpoint = getFolioProfileAddressesEndpoint(code, profileId);

    return dispatch({
      [CALL_API]: {
        endpoint,
        payload: folioId,
        types: [
          types.FETCH_FOLIO_PROFILE_REQUEST,
          types.FETCH_FOLIO_PROFILE_SUCCESS,
          types.FETCH_FOLIO_PROFILE_FAILURE,
        ],
      },
    });
  };

export const updateFolioProfileDetails =
  (folioId: string, profileId: string, data: PatchIndividualDetailsRequest) =>
  (dispatch: Dispatch, getState: GetState) => {
    return dispatch({
      [CALL_API]: {
        data: {
          details: data,
        },
        endpoint: api.PROFILES.PROFILE(profileId),
        payload: {
          profileId,
          details: data,
        },
        checkFailureCondition: (response: AxiosResponse) => {
          return refetchFolioProfile(response, profileId, folioId, dispatch);
        },
        getOptions: () => {
          const folios = getFolios(getState());
          const folioForProfile = folios.find((item) => item.id === folioId);

          return getIfMatchConfig(folioForProfile?.profile?.version);
        },
        method: 'PATCH',
        types: [
          types.UPDATE_FOLIO_PROFILE_DETAILS_REQUEST,
          types.UPDATE_FOLIO_PROFILE_DETAILS_SUCCESS,
          types.UPDATE_FOLIO_PROFILE_DETAILS_FAILURE,
        ],
      },
    });
  };

export const addTransaction =
  (
    {
      folioId,
      version,
      creditCardAuthorizationId,
    }: { folioId: string; version: number; creditCardAuthorizationId?: string },
    transaction: CreateTransactionModel,
    pendingTransaction?: any
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const cashieringAccount = getCashieringAccount(state);
    const workstationId = getWorkstationId(state);
    const paymentDevice = getPaymentDevice(state);
    const { id: accountId } = cashieringAccount;

    const options = getDefaultsForAddingTransaction(
      paymentDevice?.uniqueId,
      workstationId
    );
    const authorizationOptions = creditCardAuthorizationId
      ? { creditCardAuthorizationId }
      : {};

    return dispatch({
      [CALL_API]: {
        headers: {
          'If-Match': version,
        },
        data: {
          transactions: [transaction],
          ...authorizationOptions,
          ...options,
          isOnSitePayment: true,
        },
        method: 'POST',
        endpoint: api.CASHIERING.ADD_BATCH(accountId, folioId),
        payload: {
          transaction,
          folioId,
          pendingTransaction,
        },
        types: [
          types.ADD_TRANSACTION_REQUEST,
          types.ADD_TRANSACTION_SUCCESS,
          types.ADD_TRANSACTION_FAILURE,
        ],
      },
    });
  };

function transactionFailed(status: string) {
  const statuses = Configurator.operationStatuses;

  return (
    status === statuses.PAYMENT_FAILURE ||
    status === statuses.FAILED ||
    status === statuses.USER_CANCELLED ||
    status === statuses.PAYMENT_REJECTED
  );
}

export const getAddTransactionStatus =
  (transactionData?: any) => (dispatch: Dispatch, getState: GetState) => {
    const operation = getAddTransactionOperation(getState());
    const { id: operationId } = operation;

    return dispatch({
      [CALL_API]: {
        repetitions: 180,
        interval: 1000,
        payload: transactionData,
        endpoint: api.CASHIERING.CREDIT_CARD_PROCESSING_STATUS(operationId),
        checkCondition: (response: AxiosResponse) => {
          const { status: operationStatus } = response.data;
          if (transactionFailed(operationStatus)) {
            throw getErrorFromFailedPaymentStatus(response.data);
          }
          if (!isFetching(getState())) {
            Api.interruptInterval();
          }

          return (
            operationStatus === Configurator.operationStatuses.PAYMENT_SUCCESS
          );
        },
        types: [
          types.FETCH_ADD_TRANSACTION_STATUS_REQUEST,
          types.FETCH_ADD_TRANSACTION_STATUS_SUCCESS,
          types.FETCH_ADD_TRANSACTION_STATUS_FAILURE,
        ],
      },
    });
  };

export const fetchPaymentMethods =
  () => (dispatch: Dispatch, getState: GetState) => {
    const cashieringAccount = getCashieringAccount(getState());
    const { id: accountId } = cashieringAccount;

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.PAYMENT_METHODS(accountId),
        types: [
          types.FETCH_PAYMENT_METHODS_REQUEST,
          types.FETCH_PAYMENT_METHODS_SUCCESS,
          types.FETCH_PAYMENT_METHODS_FAILURE,
        ],
      },
    });
  };

export const voidAuthorization =
  (authorization: any) => (dispatch: Dispatch, getState: GetState) => {
    const { id, version } = authorization;

    return dispatch({
      [CALL_API]: {
        headers: {
          'If-Match': version,
        },
        method: 'DELETE',
        endpoint: api.CASHIERING.VOID_AUTHORIZATION(id),
        types: [
          types.VOID_AUTHORIZATION_REQUEST,
          types.VOID_AUTHORIZATION_SUCCESS,
          types.VOID_AUTHORIZATION_FAILURE,
        ],
      },
    });
  };

export const getVoidAuthorizationStatus =
  () => (dispatch: Dispatch, getState: GetState) => {
    const operation = getVoidAuthorizationOperation(getState());
    const { id: operationId } = operation;

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.CREDIT_CARD_PROCESSING_STATUS(operationId),
        checkCondition: (response: AxiosResponse) => {
          const { status: operationStatus } = response.data;

          return (
            operationStatus ===
              Configurator.operationStatuses.PAYMENT_FAILURE ||
            operationStatus === Configurator.operationStatuses.PAYMENT_SUCCESS
          );
        },
        types: [
          types.FETCH_VOID_AUTHORIZATION_STATUS_REQUEST,
          types.FETCH_VOID_AUTHORIZATION_STATUS_SUCCESS,
          types.FETCH_VOID_AUTHORIZATION_STATUS_FAILURE,
        ],
      },
    });
  };

export const updateAuthorization =
  (authorization: any, approvalAmount: string) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { id, version } = authorization;
    const authorizationDetails = getAuthorizationDetails(getState());
    const {
      accountId,
      authorizationRule,
      folioWindowAssignments: folioWindowIds,
    } = authorizationDetails;

    return dispatch({
      [CALL_API]: {
        data: {
          accountId,
          authorizationRule,
          folioWindowIds,
          approvalAmount,
        },
        headers: {
          'If-Match': version,
        },
        method: 'PATCH',
        endpoint: api.CASHIERING.AUTHORIZATION(id),
        types: [
          types.UPDATE_AUTHORIZATION_REQUEST,
          types.UPDATE_AUTHORIZATION_SUCCESS,
          types.UPDATE_AUTHORIZATION_FAILURE,
        ],
      },
    });
  };

export const fetchAuthorization =
  (authorization: any) => (dispatch: Dispatch) => {
    const { id } = authorization;

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.AUTHORIZATION(id),
        types: [
          types.FETCH_AUTHORIZATION_REQUEST,
          types.FETCH_AUTHORIZATION_SUCCESS,
          types.FETCH_AUTHORIZATION_FAILURE,
        ],
      },
    });
  };

export const getUpdateAuthorizationStatus =
  () => (dispatch: Dispatch, getState: GetState) => {
    const operation = getUpdateAuthorizationOperation(getState());
    const { id: operationId } = operation;

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.CREDIT_CARD_PROCESSING_STATUS(operationId),
        checkCondition: (response: AxiosResponse) => {
          const { status: operationStatus } = response.data;

          return (
            operationStatus ===
              Configurator.operationStatuses.PAYMENT_FAILURE ||
            operationStatus === Configurator.operationStatuses.PAYMENT_SUCCESS
          );
        },
        types: [
          types.FETCH_UPDATE_AUTHORIZATION_STATUS_REQUEST,
          types.FETCH_UPDATE_AUTHORIZATION_STATUS_SUCCESS,
          types.FETCH_UPDATE_AUTHORIZATION_STATUS_FAILURE,
        ],
      },
    });
  };

export const deletePaymentMethod = (accountId: string, methodId: string) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: api.CASHIERING.PAYMENT_METHOD(accountId, methodId),
    types: [
      types.DELETE_PAYMENT_METHOD_REQUEST,
      types.DELETE_PAYMENT_METHOD_SUCCESS,
      types.DELETE_PAYMENT_METHOD_FAILURE,
    ],
  },
});

export const clearCashieringErrors = () => ({
  type: types.CLEAR_CASHIERING_ERRORS,
});

export const clearPreAuthorization = () => ({
  type: types.CLEAR_PRE_AUTHORIZATION,
});

export const setMinibarPendingCharges = (amount: number) => ({
  payload: amount,
  type: types.SET_MINIBAR_PENDING_CHARGES,
});

export const checkOutFolioSeparately =
  (folio: ChargesFolio | Folio) =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.CHECK_OUT_FOLIO_SEPARATELY_REQUEST });
      await dispatch(checkOutFolio(folio));
      const checkOutFolioOperation = getCheckOutFolioOperation(getState());
      const { ids: checkOutFolioIds } = checkOutFolioOperation;
      await Promise.all(
        checkOutFolioIds.map((id) =>
          dispatch(getCheckOutFolioStatus(id.operationId))
        )
      );
      const wrongVersionedFolioIds = getWrongVersionedFolioIds(getState());
      if (wrongVersionedFolioIds.length) {
        await Promise.all(
          wrongVersionedFolioIds.map((id) => dispatch(fetchFolio(id)))
        );
        const folios = getFolios(getState());
        await Promise.all(
          folios
            .filter((folio) => wrongVersionedFolioIds.includes(folio.id))
            .map((folio) => dispatch(checkOutFolio(folio)))
        );
        const checkOutFolioOperation = getCheckOutFolioOperation(getState());
        const { ids: checkOutFolioIds } = checkOutFolioOperation;
        await Promise.all(
          checkOutFolioIds.map((id) =>
            dispatch(getCheckOutFolioStatus(id.operationId))
          )
        );
      }
      dispatch(setFolioSettlementState(folio.id));

      return dispatch({ type: types.CHECK_OUT_FOLIO_SEPARATELY_SUCCESS });
    } catch (error) {
      return dispatch({
        type: types.CHECK_OUT_FOLIO_SEPARATELY_FAILURE,
        payload: error,
      });
    }
  };

export const fullCheckOut =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.FULL_CHECK_OUT_REQUEST });
      const state = getState();
      const folios = getFoliosForCheckOut(state);
      const rcoFolios = folios.filter(
        (item) =>
          item.remoteCheckOutAllowed &&
          item.folioStatusCode.code !== Configurator.folioStatusCodes.SETTLED &&
          !item.isRecentlySettled
      );
      const leaveOpenAccount = shouldLeaveOpenAccount(state);
      await rcoFolios.reduce(async (prev, nextFolio) => {
        await prev;

        return dispatch(checkOutFolio(nextFolio));
      }, Promise.resolve());
      const errors = getCashieringErrors(getState());
      if (errors.length) {
        throw new Error(errors[0].message);
      }
      const checkOutFolioOperation = getCheckOutFolioOperation(getState());
      const { ids: checkOutFolioIds } = checkOutFolioOperation;
      await Promise.all(
        checkOutFolioIds.map((id) =>
          dispatch(getCheckOutFolioStatus(id.operationId))
        )
      );
      const wrongVersionedFolioIds = getWrongVersionedFolioIds(getState());
      if (wrongVersionedFolioIds.length) {
        await Promise.all(
          wrongVersionedFolioIds.map((id) => dispatch(fetchFolio(id)))
        );
        const folios = getFolios(getState());
        await Promise.all(
          folios
            .filter((folio) => wrongVersionedFolioIds.includes(folio.id))
            .map((folio) => dispatch(checkOutFolio(folio)))
        );
        const checkOutFolioOperation = getCheckOutFolioOperation(getState());
        const { ids: checkOutFolioIds } = checkOutFolioOperation;
        await Promise.all(
          checkOutFolioIds.map((id) =>
            dispatch(getCheckOutFolioStatus(id.operationId))
          )
        );
      }
      await Promise.all(
        rcoFolios.map((folio) => sendFinalInvoiceMail(folio.id))
      );
      await dispatch(checkOut({ leaveOpenAccount }));
      await dispatch(getCheckOutStatus());

      return dispatch({ type: types.FULL_CHECK_OUT_SUCCESS });
    } catch (error) {
      return dispatch({ type: types.FULL_CHECK_OUT_FAILURE, payload: error });
    }
  };

export const linkPaymentMethod =
  (folio: { id: string; version: number }, paymentMethodId: string) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const { accountId } = getReservation(state);

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.LINK_PAYMENT_METHOD(accountId, folio.id),
        checkFailureCondition: (response: AxiosResponse) =>
          refetchFolios(response, dispatch),
        getOptions: () => {
          const folios = getFolios(state);
          const folioWithSpecifiedId = folios.find(
            (item) => item.id === folio.id
          );

          return {
            headers: {
              'If-Match': folioWithSpecifiedId!.version,
            },
          };
        },
        method: 'POST',
        data: { paymentMethodId },
        types: [
          types.LINK_PAYMENT_METHOD_REQUEST,
          types.LINK_PAYMENT_METHOD_SUCCESS,
          types.LINK_PAYMENT_METHOD_FAILURE,
        ],
      },
    });
  };

export const transferTransactions = (data: any): CallApiAction => ({
  [CALL_API]: {
    data,
    method: 'POST',
    payload: data,
    endpoint: api.CASHIERING.TRANSFER_TRANSACTIONS,
    types: [
      types.TRANSFER_TRANSACTIONS_REQUEST,
      types.TRANSFER_TRANSACTIONS_SUCCESS,
      types.TRANSFER_TRANSACTIONS_FAILURE,
    ],
  },
});

export const refetchFolios = async (
  response: AxiosResponse,
  dispatch: Dispatch
) => {
  const isVersionCorrect = response.status !== 412;
  if (isVersionCorrect) return true;
  await dispatch(fetchFolios());

  return false;
};

export const saveEmailToSendInvoice = (emailToSendInvoice: string[]) => ({
  payload: emailToSendInvoice,
  type: types.SAVE_EMAIL_TO_SEND_INVOICE,
});

export const setFolioSettlementState = (folioId: string) => ({
  payload: folioId,
  type: types.SET_FOLIO_SETTLEMENT_STATE,
});

export const fetchKioskAuthorizationAmount =
  () => (dispatch: Dispatch, getState: GetState) => {
    const accountId = getAccountId(getState());
    const deductDepositPayment = Configurator.getSwitch(
      Configurator.switchCodes.PRE_AUTH_DEDUCT_DEPOSIT
    );

    const deductRoomCharge = Configurator.getSwitch(
      Configurator.switchCodes.PRE_AUTH_DEDUCT_BI
    );

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.KIOSK_AUTHORIZATION_AMOUNT(accountId),
        params: {
          deductDepositPayment,
          deductRoomCharge,
        },
        types: [
          types.FETCH_KIOSK_AUTHORIZATION_AMOUNT_REQUEST,
          types.FETCH_KIOSK_AUTHORIZATION_AMOUNT_SUCCESS,
          types.FETCH_KIOSK_AUTHORIZATION_AMOUNT_FAILURE,
        ],
      },
    });
  };

export const fetchNotPostedCharges =
  () => (dispatch: Dispatch, getState: GetState) => {
    const accountId = getAccountId(getState());

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.RESERVATION_CHARGES(accountId),
        params: {
          isPosted: false,
        },
        types: [
          types.FETCH_NOT_POSTED_CHARGES_REQUEST,
          types.FETCH_NOT_POSTED_CHARGES_SUCCESS,
          types.FETCH_NOT_POSTED_CHARGES_FAILURE,
        ],
      },
    });
  };

export const getChargeReservationStatus =
  () => (dispatch: Dispatch, getState: GetState) => {
    const { id: operationId } = getChargeReservationOperation(getState());

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.CHARGE_RESERVATIONS_STATUS(operationId),
        payload: operationId,
        checkCondition: (response: AxiosResponse) => {
          const { operationStatus } = response.data.status;

          return operationStatus === Configurator.operationStatuses.COMPLETED;
        },
        types: [
          types.FETCH_CHARGE_RESERVATION_STATUS_REQUEST,
          types.FETCH_CHARGE_RESERVATION_STATUS_SUCCESS,
          types.FETCH_CHARGE_RESERVATION_STATUS_FAILURE,
        ],
      },
    });
  };

export const chargeReservation = (
  reservationId: string,
  chargeReservationMode = 'SelectedUntilEndOfStay'
) => ({
  [CALL_API]: {
    data: {
      chargeReservationMode,
      cashierNumber: Configurator.cashieringSettings.cashierNumber,
      reservationIds: [reservationId],
      cashierSessionId: '00000000-0000-0000-0000-000000000000',
    },
    method: 'POST',
    endpoint: api.CASHIERING.CHARGE_RESERVATIONS,
    types: [
      types.CHARGE_RESERVATION_REQUEST,
      types.CHARGE_RESERVATION_SUCCESS,
      types.CHARGE_RESERVATION_FAILURE,
    ],
  },
});

export const updateFolioStyleCode =
  (folioId: string, folioStyleCode: string) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const accountId = getAccountId(state);
    const folios = getFolios(state);
    const selectedFolio = folios.find((folio) => folio.id === folioId);

    if (
      !selectedFolio ||
      selectedFolio?.folioStyleCode?.code === folioStyleCode
    ) {
      return;
    }

    const data: Partial<PatchFolio> = {
      folioStyleCode,
    };

    return dispatch({
      [CALL_API]: {
        method: 'PATCH',
        endpoint: api.CASHIERING.FOLIO(accountId, folioId),
        data,
        headers: {
          'If-Match': selectedFolio.version,
        },
        types: [
          types.UPDATE_FOLIO_STYLE_CODE_REQUEST,
          types.UPDATE_FOLIO_STYLE_CODE_SUCCESS,
          types.UPDATE_FOLIO_STYLE_CODE_FAILURE,
        ],
      },
    });
  };

export const fetchAdditionalFolioInfo =
  (id: string) => async (dispatch: Dispatch, getState: GetState) => {
    const folios = getAllFolios(getState());
    const folioOfId = folios.find((folio) => folio.id === id);
    if (!folioOfId) return;

    await dispatch(fetchFolioTransactions(id));

    if (folioOfId.profileId) {
      await dispatch(fetchFolioProfile(folioOfId.profileId, id));
    }

    if (
      Configurator.getSwitch(
        Configurator.switchCodes.SHOW_FISCAL_DOCUMENT_SELECTION
      )
    ) {
      await dispatch(fetchAvailableFolioStyles(id));
    }

    const detailedFolios = getAllDetailedFolios(getState());
    const detailedFolioOfId = detailedFolios.find((folio) => folio.id === id);
    const addressCountryCode = detailedFolioOfId?.address?.countryCode;

    if (!detailedFolioOfId?.address || !addressCountryCode) return;
    const ifStatesShouldBeFetched = detailedFolioOfId.address.stateCode;
    const ifDistrictsShouldBeFetched = detailedFolioOfId.address.districtId;

    await Promise.all([
      ...(ifStatesShouldBeFetched
        ? [dispatch(fetchStates(addressCountryCode, i18next.language))]
        : []),
      ...(ifDistrictsShouldBeFetched
        ? [dispatch(fetchDistricts(addressCountryCode, i18next.language))]
        : []),
    ]);
  };

export const fetchAvailableFolioStyles =
  (folioId: string) => (dispatch: Dispatch, getState: GetState) => {
    const accountId = getAccountId(getState());

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.AVAILABLE_FOLIO_STYLES(accountId, folioId),
        payload: folioId,
        types: [
          types.FETCH_AVAILABLE_FOLIO_STYLES_REQUEST,
          types.FETCH_AVAILABLE_FOLIO_STYLES_SUCCESS,
          types.FETCH_AVAILABLE_FOLIO_STYLES_FAILURE,
        ],
      },
    });
  };
