const FETCH_MINIBAR_ITEMS_REQUEST = 'FETCH_MINIBAR_ITEMS_REQUEST';
const FETCH_MINIBAR_ITEMS_SUCCESS = 'FETCH_MINIBAR_ITEMS_SUCCESS';
const FETCH_MINIBAR_ITEMS_FAILURE = 'FETCH_MINIBAR_ITEMS_FAILURE';

const FETCH_CURRENT_DATE_REQUEST = 'FETCH_CURRENT_DATE_REQUEST';
const FETCH_CURRENT_DATE_SUCCESS = 'FETCH_CURRENT_DATE_SUCCESS';
const FETCH_CURRENT_DATE_FAILURE = 'FETCH_CURRENT_DATE_FAILURE';

export default {
  FETCH_MINIBAR_ITEMS_REQUEST,
  FETCH_MINIBAR_ITEMS_SUCCESS,
  FETCH_MINIBAR_ITEMS_FAILURE,
  FETCH_CURRENT_DATE_REQUEST,
  FETCH_CURRENT_DATE_SUCCESS,
  FETCH_CURRENT_DATE_FAILURE,
};
