import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Big from 'big.js';
import {
  Body,
  Footer,
  FormHeader,
  Header,
  ReservationHeader,
  View,
} from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import { change, getFormValues, InjectedFormProps, submit } from 'redux-form';
import {
  addTransaction,
  setMinibarPendingCharges,
} from 'store/cashiering/actions';
import { getMinibarPendingCharges } from 'store/cashiering/selectors';
import { fetchReservationPurchases } from 'store/reservation/actions';
import {
  getGuestsNumber,
  getReservation,
  getReservationVersion,
} from 'store/reservation/selectors';
import { ChargesFolio } from 'types/Api/Cashiering';
import Store from 'types/Store';
import { Configurator } from 'utils';

import { Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import { fetchCurrentDate, fetchMinibarItems } from './store/actions';
import { getCurrentPrices, getMinibarDate } from './store/selectors';
import styles from './CheckOutMinibar.style';
import MinibarMenu from './MinibarMenu';
import OrderSummaryList from './OrderSummaryList';

const ORDER_WRAPPER_SPACING = 2;
const NUMBER_TO_ADD = 1;
const NUMBER_TO_REMOVE = -1;

export interface PassedProps {
  onSubmit: () => void;
  onClose: () => void;
  folio: ChargesFolio;
}

interface CheckOutMinibarProps
  extends PassedProps,
    InjectedFormProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  submit: (form: string) => void;
  onChange: (form: string, field: string, value: any) => void;
  fetchReservationPurchases: (reservationId: string) => void;
  fetchMinibarItems: (startDate: string, endDate: string) => void;
  fetchCurrentDate: () => void;
  updateReservationPurchases: (id: string, data: any, version: number) => void;
  orderedItems?: any;
  guestNumber: number;
  version: number;
  reservation: {
    id: string;
    profileId: string;
    roomId: string;
    roomTypeId: string;
    statusCode: {
      code: string;
    };
    arrivalDate: string;
    departureDate: string;
  };
  prices: {
    [index: string]: any;
  };
  minibarPendingCharges: number;
  addTransaction: typeof addTransaction;
  setMinibarPendingCharges: typeof setMinibarPendingCharges;
}

interface CheckOutMinibarState {
  activeField: string | null;
  isSubmitActive: boolean;
  isMinibarUpdating: boolean;
}

class CheckOutMinibar extends PureComponent<
  CheckOutMinibarProps,
  CheckOutMinibarState
> {
  public static defaultProps = {
    minibarItems: [],
  };

  public state = {
    activeField: null,
    isSubmitActive: true,
    isMinibarUpdating: false,
  };

  public async componentDidMount() {
    const {
      fetchReservationPurchases,
      fetchMinibarItems,
      fetchCurrentDate,
      reservation: { id, arrivalDate, departureDate },
    } = this.props;
    await fetchCurrentDate();
    fetchReservationPurchases(id);
    fetchMinibarItems(arrivalDate, departureDate);
  }

  public render() {
    const { classes, t, orderedItems, onClose } = this.props;

    return (
      <View
        modal={{ isLoading: this.getSubmitState() }}
        idle={{ type: 'modal' }}
      >
        <Header title="Check Out - Minibar" />
        <ReservationHeader />
        <Body className={classes.bodyWrapper}>
          <FormHeader
            title={t('RECENT_CONSUMPTION')}
            subtitle={t('ALL_PRICES', {
              currency: Configurator.propertySettings.currencyCode,
            })}
          />
          <Grid
            container
            spacing={ORDER_WRAPPER_SPACING}
            className={classes.orderWrapper}
          >
            <MinibarMenu
              onItemClick={this.onItemAdd}
              items={Configurator.minibarItems}
              orderedItems={orderedItems}
            />
            <OrderSummaryList
              items={this.mapOrderedItems(orderedItems)}
              onItemAdd={this.onItemAdd}
              onItemRemove={this.onItemRemove}
              onDeleteClick={this.onDeleteClick}
              toggleActiveField={this.toggleActiveField}
              onFormSubmit={this.onFormSubmit}
            />
          </Grid>
        </Body>
        <Footer
          hasCancelButton
          hasContinueButton
          isOnContinuePrior
          onContinue={this.onFormSubmit}
          onCancel={onClose}
          isContinueDisabled={this.getSubmitState()}
        />
      </View>
    );
  }

  public onItemAdd = (itemName: string) => this.onItemClick(itemName, false);
  public onItemRemove = (itemName: string) => this.onItemClick(itemName, true);

  public onItemClick = (itemName: string, shouldRemove: boolean) => {
    const { onChange, orderedItems } = this.props;
    const isFieldRegistered = !!(orderedItems && orderedItems[itemName]);
    const attribute = shouldRemove ? NUMBER_TO_REMOVE : NUMBER_TO_ADD;
    const value = isFieldRegistered ? orderedItems[itemName] + attribute : 1;
    onChange(ids.MINIBAR_FORM, itemName, value);
  };

  public onDeleteClick = (itemName: string) => {
    const { onChange } = this.props;
    onChange(ids.MINIBAR_FORM, itemName, null);
  };

  private getSubmitState = () => {
    const { orderedItems } = this.props;
    const { isMinibarUpdating } = this.state;
    const items = this.mapOrderedItems(orderedItems);

    return (
      items.some((item) => item.max && item.quantity > item.max) ||
      isMinibarUpdating
    );
  };

  private onFormSubmit = async () => {
    const { orderedItems, onSubmit, setMinibarPendingCharges } = this.props;
    this.toggleIsMinibarUpdating();
    await setMinibarPendingCharges(0);
    const items = this.mapOrderedItems(orderedItems);
    await items.reduce(async (prev, nextItem) => {
      await prev;

      return this.addMinibarItem(nextItem);
    }, Promise.resolve());
    await setMinibarPendingCharges(0);
    this.toggleIsMinibarUpdating();

    return onSubmit();
  };

  private addMinibarItem = async ({
    quantity,
    price,
    transactionCode,
  }: {
    quantity: number;
    price: number;
    transactionCode: string;
  }) => {
    const {
      addTransaction,
      setMinibarPendingCharges,
      minibarPendingCharges,
      folio: { version, id: folioId },
    } = this.props;

    const transaction = {
      transactionCode,
      quantity,
      foreignUnitPrice: null,
      isMainTransaction: true,
      unitPrice: {
        amount: price,
        currency: Configurator.propertySettings.currencyCode,
      },
    };

    const folioData = {
      folioId,
      version,
    };

    const pendingTransaction = {
      quantity,
      businessDate: Configurator.propertyBusinessDate,
      id: '',
      transactionGroupTypeCode: Configurator.transactionCodes.REVENUE,
      grossAmount: {
        amount: new Big(price).times(quantity).toNumber(),
      },
      grossUnitPrice: {
        amount: price,
      },
      transactionCode: {
        code: transactionCode,
        description:
          Configurator.getTransactionCodeDescription(transactionCode),
      },
      netAmount: {
        amount: new Big(price).times(quantity).toNumber(),
      },
      netUnitPrice: {
        amount: price,
      },
    };

    const mockData = {
      folioId,
      pendingTransaction,
    };

    const pendingCharges = new Big(price)
      .times(quantity)
      .plus(minibarPendingCharges)
      .toNumber();

    await addTransaction(folioData, transaction, mockData);
    await setMinibarPendingCharges(pendingCharges);
  };

  private mapOrderedItems = (orderedItems: any) => {
    const { activeField } = this.state;

    return orderedItems
      ? Object.keys(orderedItems)
          .filter(
            (item: any) =>
              (orderedItems[item] && orderedItems[item] > 0) ||
              activeField === item
          )
          .map((item: any) => {
            const minibarItem = Configurator.minibarItems.find(
              (elem) => elem.id === item
            )!;

            return {
              title: minibarItem.title,
              quantity: orderedItems[item],
              price: minibarItem.price,
              id: item,
              transactionCode: minibarItem.transactionCode,
              max: minibarItem.maxQuantity,
            };
          })
      : [];
  };

  private toggleActiveField = (fieldName: string) => {
    const { activeField } = this.state;
    this.setState({
      activeField: activeField === fieldName ? null : fieldName,
    });
  };

  private toggleIsMinibarUpdating = () => {
    const { isMinibarUpdating } = this.state;
    this.setState({ isMinibarUpdating: !isMinibarUpdating });
  };
}

const mapStateToProps = (state: Store) => ({
  orderedItems: getFormValues(ids.MINIBAR_FORM)(state),
  minibarPendingCharges: getMinibarPendingCharges(state),
  reservation: getReservation(state),
  date: getMinibarDate(state),
  guestNumber: getGuestsNumber(state),
  prices: getCurrentPrices(state),
  version: getReservationVersion(state),
});

const mapDispatchToProps = {
  setMinibarPendingCharges,
  addTransaction,
  fetchReservationPurchases,
  fetchMinibarItems,
  fetchCurrentDate,
  submit,
  onChange: change,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckOutMinibar) as (props: PassedProps) => JSX.Element;
