import { ReducersMapObject } from 'redux';

import { Action } from '@ac/library-utils/dist/declarations';

import { backgroundTasks } from '@gss/store/backgroundTasks/reducer';
import { configuration } from '@gss/store/configuration/reducer';
import { mainProcess } from '@gss/store/mainProcess/reducer';
import { settings } from '@gss/store/settings/reducer';

import { GlobalStores } from '../interfaces/globalStores';

export const globalReducers: ReducersMapObject<GlobalStores, Action> = {
  configuration,
  settings,
  backgroundTasks,
  mainProcess,
};
