import { createSelector } from 'reselect';
import Store from 'types/Store/CheckOutMinibarStore';
import { Configurator, DateManager } from 'utils';

export const getDetailedMinibarItems = (state: Store) =>
  state.checkOutMinibar.minibarItems;

export const getMinibarDate = (state: Store) => {
  return DateManager.getFormattedDate(
    DateManager.getPreviousDay(state.checkOutMinibar.businessDate),
    Configurator.dateFormat?.shortDateFormat
  );
};

export const getCurrentPrices = createSelector(
  getDetailedMinibarItems,
  getMinibarDate,
  (items, date) =>
    items.reduce((prices: { [index: string]: any }, item) => {
      const dailyPrice = item.dailyPrices.find((price) => price.day === date);
      // prices[item.id] = dailyPrice ? dailyPrice.price[item.basePriceType.toLowerCase()] : 0;
      prices[item.id] = dailyPrice ? dailyPrice.price.gross : 0;

      return prices;
    }, {})
);
