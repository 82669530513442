import { CallApiResponseAction } from 'store/middleware/api';
import { State } from 'types/Store/CheckOutMinibarStore';

import types from './types';

const initialState: State = {
  isFetching: false,
  errors: [],
  minibarItems: [],
  businessDate: '',
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_MINIBAR_ITEMS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_MINIBAR_ITEMS_SUCCESS: {
      const { results: minibarItems } = action.response.data;

      return {
        ...state,
        minibarItems,
        isFetching: false,
      };
    }
    case types.FETCH_MINIBAR_ITEMS_FAILURE: {
      const { details } = action.response.data;
      const { errors } = state;

      return {
        ...state,
        errors: [...errors, ...details],
        isFetching: false,
      };
    }

    case types.FETCH_CURRENT_DATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_CURRENT_DATE_SUCCESS: {
      const { businessDate } = action.response.data;

      return {
        ...state,
        businessDate,
        isFetching: false,
      };
    }
    case types.FETCH_CURRENT_DATE_FAILURE: {
      const { details } = action.response.data;
      const { errors } = state;

      return {
        ...state,
        errors: [...errors, ...details],
        isFetching: false,
      };
    }

    default:
      return state;
  }
};
