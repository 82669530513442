import { CallApiResponseAction } from 'store/middleware/api';
import { PurchaseElementData } from 'types/Api/Reservation';
import { State } from 'types/Store/CheckInAddonStore';

import types from './types';

const initialState: State = {
  isFetching: false,
  confirmedNewPurchaseOrders: [],
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_SUCCESS: {
      const { results } = action.response.data;
      const {
        payload: { previousPreAddedElements, orderdItemsIds },
      } = action;

      const confirmedNewPurchaseOrders = results.filter(
        ({ id, purchaseElementId }: PurchaseElementData) =>
          orderdItemsIds.includes(purchaseElementId) &&
          !previousPreAddedElements.find(
            (item: PurchaseElementData) => item.id === id
          )
      );

      return {
        ...state,
        confirmedNewPurchaseOrders,
        isFetching: false,
      };
    }
    case types.FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case types.CLEAR_CONFIRMED_NEW_PURCHASE_ORDERS: {
      return {
        ...state,
        confirmedNewPurchaseOrders: [],
      };
    }

    default:
      return state;
  }
};
